<template>
  <b-modal

    id="edit-emma-contact-modal"
    :no-close-on-backdrop="true"
    cancel-variant="outline-secondary"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    centered
    :title="$t('editContact')"
    @hidden="resetModal"
    @show="loadData"
    @ok="handleSubmit"
  >
    <b-form>
      <b-form-group>
        <label for="name"> <h6>{{ $t('name') }}</h6> </label>
        <b-form-input
          id="name"
          v-model="name"
          class="mb-1"
        />
        <label for="email"> <h6>{{ $t('email') }}</h6> </label>
        <b-form-input
          id="email"
          v-model="email"
          class="mb-1"
        />
        <label for="phone"> <h6>{{ $t('phoneNumber') }}</h6> </label>
        <b-form-input
          id="phone"
          v-model="phoneNumber"
          :state="numberValid"
          placeholder="+436601234567"
          class="mb-1"
        />
        <div v-if="contactList.length > 1">
          <label for="maincontactCheckbox"> <h6>{{ $t('mainContact') }}</h6> </label>
          <b-form-checkbox
            id="maincontactCheckbox"
            v-model="mainContact"
          />
        </div>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormCheckbox, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AddEmmaContactModal',
  components: {
    BFormCheckbox,
    BFormGroup,
    BForm,
    BFormInput,
  },
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: '',
      name: '',
      phoneNumber: '',
      email: '',
      mainContact: false,
      submitPressed: false,
    }
  },
  computed: {
    contactList() {
      return this.$store.state.emmasettings.contacts
    },
    numberValid() {
      if (!this.submitPressed) {
        return undefined
      }
      return this.isValidPhoneNumber()
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    resetModal() {
    },
    loadData() {
      this.id = this.selectedUser.id
      this.name = this.selectedUser.name
      this.email = this.selectedUser.email
      this.phoneNumber = this.selectedUser.telephone
      this.mainContact = this.selectedUser.maincontact
      this.did = this.selectedUser.did
      this.submitPressed = false
    },
    isValidPhoneNumber() {
      const number = this.phoneNumber.trim()
      const regex = /^\+[0-9]{2,}$/
      return regex.test(number)
    },
    async handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()

      this.submitPressed = true
      if (!this.numberValid) {
        return
      }

      const contact = {
        id: this.id,
        name: this.name,
        email: this.email,
        telephone: this.phoneNumber,
        maincontact: this.mainContact,
        newDid: this.selectedUser.did,
      }
      if (this.contactList.length === 1) {
        contact.maincontact = true
      }
      try {
        await this.$http.put(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/contacts/${this.id}`, contact)
        const toastText = `Kontakt ${this.name} erfolgreich bearbeitet`
        this.$toast({
          component: ToastificationContent,
          props: {
            title: toastText,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.$store.dispatch('emmasettings/getContactList')
      } catch (e) {
        console.error(e)
        const toastText = `Kontakt ${this.name} konnte nicht bearbeitet werden`
        this.$toast({
          component: ToastificationContent,
          props: {
            title: toastText,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }

      this.$nextTick(() => {
        this.$bvModal.hide('edit-emma-contact-modal')
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
