<template>
  <div
    class="app-calendar overflow-hidden border"
  >
    <div class="row no-gutters">
      <!-- Calendar -->
      <div class="col position-relative">
        <div
          class="card shadow-none border-0 mb-0 rounded-0"
        >
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
              @event-clicked="handleEventClicked"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :scope="scope"
        :event="selectedEvent"
        @submitted="isEventHandlerSidebarActive = false; refreshKey += 1"
        @cancelled="isEventHandlerSidebarActive = false"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import allLocales from '@fullcalendar/core/locales-all'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'

export default {
  name: 'Calendar',
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarEventHandler,
  },
  props: {
    scope: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEventHandlerSidebarActive: false,
      selectedEvent: {},
      refreshKey: 0,
    }
  },
  computed: {
    events() {
      // eslint-disable-next-line no-unused-expressions
      this.refreshKey
      let allEvents = []
      allEvents = allEvents.concat(this.$store.state.calendar.clusterEvents)
      if (this.scope.type === 'cluster') {
        for (let i = 0; i < allEvents.length; i += 1) {
          allEvents[i].editable = true
        }
        return allEvents
      }
      for (let i = 0; i < allEvents.length; i += 1) {
        allEvents[i].editable = false
      }
      allEvents = allEvents.concat(this.$store.state.calendar.emmaEvents)
      return allEvents
    },
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        events: this.events,
        editable: true,
        locales: allLocales,
        locale: this.$i18n.locale,
        eventResizableFromStart: true,
        dragScroll: true,
        dayMaxEvents: 2,
        slotEventOverlap: false,
        navLinks: true,
        eventClassNames: this.getClassName,
        eventClick: this.handleEventClicked,
        eventDrop: this.handleDrop,
        dateClick: this.handleDateClick,
      }
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('calendar/getEmmaEvents', this.scope.id)
      this.makeToast('Termine geladen!', '', 'CheckSquareIcon', 'success')
    } catch (e) {
      this.makeToast('Termine konnten nicht geladen werden!', '', 'XIcon', 'danger')
    }
  },
  methods: {
    getClassName(event) {
      const calendarsColor = {
        emma: 'primary',
        cluster: 'danger',
      }
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[event.event.extendedProps.calendar]
      return [`bg-light-${colorName}`]
    },
    handleEventClicked(clickedEvent) {
      if (clickedEvent.event.durationEditable === false) {
        this.makeToast('Achtung', 'Haus-Termine können im Emma Kalender nicht verändert werden!', 'XIcon', 'danger')
        return
      }
      if (clickedEvent.event.extendedProps.calendar === 'cluster') {
        [this.selectedEvent] = this.$store.getters['calendar/getClusterCalendarEventById'](Number.parseInt(clickedEvent.event.id, 10))
      }
      if (clickedEvent.event.extendedProps.calendar === 'emma') {
        [this.selectedEvent] = this.$store.getters['calendar/getEmmaCalendarEventById'](Number.parseInt(clickedEvent.event.id, 10))
      }

      const audioBlob = this.selectedEvent.audio
      this.selectedEvent = JSON.parse(JSON.stringify(this.selectedEvent))
      this.selectedEvent.audio = audioBlob

      this.isEventHandlerSidebarActive = true
    },
    handleDateClick(clickedDate) {
      const clickedDatePlusOneHour = new Date(new Date(clickedDate.date).setHours((clickedDate.date).getHours() + 1))
      this.selectedEvent = {
        title: '',
        start: clickedDate.date.toISOString(),
        end: clickedDatePlusOneHour.toISOString(),
        allday: false,
        calendar: this.scope.type,
        scopeId: this.scope.id,
        audio: undefined,
      }
      this.isEventHandlerSidebarActive = true
    },
    async handleDrop(info) {
      const updatedEvent = {
        id: Number.parseInt(info.event.id, 10),
        title: info.event.title,
        start: info.event.start,
        allday: info.event.extendedProps.allday,
        calendar: info.event.extendedProps.calendar,
        scopeId: this.scope.id,
        audio: info.event.extendedProps.audio,
      }
      if (info.event.end !== null) {
        updatedEvent.end = info.event.end
      } else {
        updatedEvent.end = info.event.start
      }

      try {
        await this.$store.dispatch('calendar/updateEmmaEvent', updatedEvent)
        this.makeToast(`Termin ${updatedEvent.title} erfolgreich bearbeitet!`, '', 'CheckSquareIcon', 'success')
      } catch (e) {
        this.makeToast(`Termin ${updatedEvent.title} konnte nicht bearbeitet werden!`, '', 'XIcon', 'danger')
      }
    },
    makeToast(title, text, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.fc-daygrid-day-number {
  color: black;
}

</style>
