<template>
  <div>
    <div v-if="selectedEmma !== undefined && selectedEmmaSettings !== undefined && !loading">
      <b-tabs @input="updateHref" v-model="tabIdx" nav-class="flex-nowrap overflow-auto">
        <b-tab lazy :title="$t('functions')">
          <emma-control-dashboard />
        </b-tab>
        <b-tab
          lazy
          :title="$t('telephony')"
        >
          <emma-call-dashboard />
        </b-tab>
        <b-tab
          lazy
          :title="$t('technical')"
          @click="techKey = Math.floor(Math.random() * 100)"
        >
          <emma-tech-dashboard :key="techKey" />
        </b-tab>
        <b-tab
          lazy
          :title="$t('calendar')"
          @click="calendarKey = Math.floor(Math.random() * 100)"
        >
          <calendar
            :key="calendarKey"
            :selected-events="['cluster', 'emma']"
            :scope="{ type: 'emma', id: selectedEmma.serial }"
          />
        </b-tab>
<!--        <b-tab-->
<!--          lazy-->
<!--          :title="$t('masterData')"-->
<!--        >-->
<!--          <alarm-management-form />-->
<!--        </b-tab>-->
        <b-tab
          lazy
          :title="$t('nuki')"
        >
          <nuki-manager />
        </b-tab>
        <b-tab
          lazy
          :title="$t('hue')"
        >
          <hue-manager />
        </b-tab>
        <b-tab
          lazy
          :title="$t('zigbee')"
        >
          <zigbee-manager />
        </b-tab>
        <b-tab
          lazy
          :title="$t('smarthome')"
        >
          <smarthome-manager />
        </b-tab>
        <b-tab
          v-if="role === 'admin'"
          lazy
          :title="$t('debug')"
        >
          <emma-debug-dashboard />
        </b-tab>
      </b-tabs>
    </div>
    <div v-else>
      <emma-table-good-table
        v-if="!loading"
        :emmas="emmas"
        :show-all-columns="true"
      />
      <b-card v-else>
        <div class="mb-1">
          <strong>{{ $t("connectingTo", { serial: this.serial }) }}</strong>
        </div>
        <b-skeleton
          animation="wave"
          width="85%"
        />
        <b-skeleton
          animation="wave"
          width="55%"
        />
        <b-skeleton
          animation="wave"
          width="70%"
        />
        <b-skeleton
          animation="wave"
          width="85%"
        />
        <b-skeleton
          animation="wave"
          width="65%"
        />
        <b-skeleton
          animation="wave"
          width="70%"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BSkeleton,
} from 'bootstrap-vue'
import emmaTableGoodTable from '@/components/management/tables/EmmaTableGoodTable.vue'
import Calendar from '@/components/calendar/Calendar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ZigbeeManager from '@/components/emma/smarthome/ZigbeeManager.vue'
// import AlarmManagementForm from '@/components/forms/AlarmManagementForm.vue'
import NukiManager from '@/components/emma/smarthome/NukiManager.vue'
import HueManager from '@/components/emma/smarthome/HueManager.vue'
import EmmaDebugDashboard from '@/views/dashboards/emma/EmmaDebugDashboard.vue'
import EmmaCallDashboard from '@/views/dashboards/emma/EmmaCallDashboard.vue'
import EmmaControlDashboard from '@/views/dashboards/emma/EmmaControlDashboard.vue'
import EmmaTechDashboard from '@/views/dashboards/emma/EmmaTechDashboard.vue'
import SmarthomeManager from '@/components/emma/smarthome/SmarthomeManager.vue'

export default {
  name: 'EmmaDashboard',
  components: {
    emmaTableGoodTable,
    EmmaTechDashboard,
    EmmaControlDashboard,
    BTab,
    BTabs,
    BCard,
    Calendar,
    EmmaCallDashboard,
    EmmaDebugDashboard,
    BSkeleton,
    ZigbeeManager,
    NukiManager,
    HueManager,
    SmarthomeManager,
  },
  data() {
    return {
      role: '',
      calendarKey: 0,
      techKey: 0,
      loading: false,
      tabIdx: 0,
    }
  },
  computed: {
    selectedEmma() {
      return this.$store.state.emmasettings.selectedEmma
    },
    selectedEmmaSettings() {
      return this.$store.state.emmasettings.settings
    },
    emmas() {
      if (this.$store.state.emmas.emmas !== undefined) {
        return this.$store.state.emmas.emmas
      }
      return []
    },
    serial() {
      return this.$route.query.serial
    },
  },
  watch: {
    async serial() {
      await this.pullEmma()
    },
  },
  async mounted() {
    this.role = JSON.parse(localStorage.getItem('userData')).role
    await this.pullEmma()
  },
  updated() {
    this.setPageTitle()
    this.$nextTick(() => {
      const tabIdx = parseInt(this.$route.query.tabIdx)
      if (tabIdx && !isNaN(tabIdx)) {
        this.tabIdx = tabIdx
      } else {
        this.tabIdx = 0
      }
    })
  },
  destroyed() {
    this.$store.commit('calendar/setEmmaEvents', [])
    this.$store.commit('emmasettings/setSelectedEmma', undefined)
    this.$store.commit('emmasettings/setSettings', undefined)
  },
  methods: {
    async pullEmma() {
      this.loading = true
      this.$store.commit(
        'emmasettings/setSelectedEmma',
        await this.$store.getters['emmas/getEmmaBySerial'](this.serial),
      )
      if (this.selectedEmma !== undefined) {
        this.$store.commit('emmasettings/setSelectedEmma', this.selectedEmma)
        if (this.selectedEmma.fake) {
          this.makeToast(
            `Emma ${this.selectedEmma.serial} kann nicht geladen werden!`,
            'EditIcon',
            'danger',
            `${this.selectedEmma.serial} ist eine Demo-Emma!`,
          )
          await this.$router.push('/dashboard/emma')
        } else {
          await this.pullEmmaSettings()
        }
      }
      this.loading = false
    },
    async pullEmmaSettings() {
      try {
        const getEmmaSettingsResponse = await this.$http.get(`/emmas/${this.selectedEmma.serial}/settings`)
        this.$store.commit('emmasettings/setSettings', getEmmaSettingsResponse.data)
        this.makeToast(`Emma ${this.selectedEmma.serial} erfolgreich geladen!`, 'EditIcon', 'success', '')
      } catch (e) {
        this.makeToast('Emma konnte nicht erreicht werden!', 'EditIcon', 'danger', '')
        this.loading = false
        await this.$router.push('/dashboard/emma')
      }
    },
    setPageTitle() {
      if (this.selectedEmma !== undefined) {
        this.$store.commit('app/SET_PAGE_TITLE', `Emma (Serial: ${this.selectedEmma.serial})`)
      } else {
        this.$store.commit('app/SET_PAGE_TITLE', 'Emmas')
      }
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
    updateHref(newTabIdx) {
      const routeTabIdx = parseInt(this.$route.query.tabIdx)
      if (routeTabIdx !== newTabIdx) {
        if (!newTabIdx) { // first tab doesn't get a parameter
          this.$router.replace('/dashboard/emma?serial=' + this.selectedEmma.serial)
        } else {
          this.$router.replace('/dashboard/emma?serial=' + this.selectedEmma.serial + '&tabIdx=' + newTabIdx)
        }
      }
    },
  },
}
</script>

<style scoped></style>
