<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ $t('nightLight') }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col>
          <b-input-group>
            <b-input-group-prepend>
              <b-button-group>
                <b-button
                  variant="primary"
                  @click="toggleNightlightStatus(true)"
                >
                  <feather-icon
                    size="14"
                    icon="ZapIcon"
                  />
                </b-button>
                <b-button
                  variant="dark"
                  @click="toggleNightlightStatus(false)"
                >
                  <feather-icon
                    size="14"
                    icon="ZapOffIcon"
                  />
                </b-button>
              </b-button-group>
            </b-input-group-prepend>
            <b-form-input
              id="bg-opacity"
              v-model="brightnessNightLight"
              debounce="100"
              type="range"
              number
              min="1"
              max="100"
              step="1"
              @update="updatedNightlightBrightnessSlider"
            />
            <b-input-group-append
              is-text
              class="text-monospace"
            >
              {{ brightnessNightLight }}
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="6">
          <label for="start-time-picker">{{ $t('turnOnAt') }}</label>
          <b-form-input
            id="start-time-picker"
            v-model="selectedStartTime"
            type="time"
          />
        </b-col>
        <b-col cols="6">
          <label for="end-time-picker">{{ $t('turnOfAt') }}</label>
          <b-form-input
            id="end-time-picker"
            v-model="selectedEndTime"
            type="time"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mt-1"
          style="text-align: center"
          cols="12"
        >
          <b-form-checkbox-group
            v-model="selectedDays"
            class="w-100"
          >
            <b-form-checkbox
              size="lg"
              value="mon"
            >
              {{ $t('mondayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="tue"
            >
              {{ $t('tuesdayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="wed"
            >
              {{ $t('wednesdayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="thu"
            >
              {{ $t('thursdayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="fri"
            >
              {{ $t('fridayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="sat"
            >
              {{ $t('saturdayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="sun"
            >
              {{ $t('sundayShort') }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'NightLightManager',
  components: {
    BButtonGroup,
    BInputGroupPrepend,
    BButton,
    BCard,
    BCardBody,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  props: {
  },
  data() {
    return {
      brightnessNightLight: 0,
      selectedStartTime: undefined,
      selectedEndTime: undefined,
      selectedDays: undefined,
    }
  },
  computed: {
    validTimes() {
      return (this.selectedStartTime && this.selectedEndTime)
    },
  },
  watch: {
    async selectedDays(newVal, oldVal) {
      if (newVal !== undefined && oldVal !== undefined) {
        await this.buildAndSendPayload()
      }
    },
    async selectedStartTime(newVal, oldVal) {
      if (newVal !== undefined && oldVal !== undefined) {
        await this.buildAndSendPayload()
      }
    },
    async selectedEndTime(newVal, oldVal) {
      if (newVal !== undefined && oldVal !== undefined) {
        await this.buildAndSendPayload()
      }
    },
  },
  mounted() {
    this.brightnessNightLight = this.$store.state.emmasettings.settings.lights.night.level
    this.selectedStartTime = this.$store.state.emmasettings.settings.lights.night.startTime || '21:00'
    this.selectedEndTime = this.$store.state.emmasettings.settings.lights.night.endTime || '07:00'
    this.selectedDays = this.$store.state.emmasettings.settings.lights.night.days || []
  },
  methods: {
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
    async buildAndSendPayload() {
      const payload = {
        startTime: this.selectedStartTime, endTime: this.selectedEndTime, days: this.selectedDays,
      }
      await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/light/schedule_nightlight`, payload)
    },
    async toggleNightlightStatus(enabled) {
      let state
      let toastText = ''
      let toastTitle = ''
      if (enabled) {
        toastTitle = 'Nachtlicht ein'
        toastText = 'Nachtlicht erfolgreich eingeschalten'
        state = 'ON'
      } else {
        toastTitle = 'Nachtlicht aus'
        toastText = 'Nachtlicht erfolgreich ausgeschalten'
        state = 'OFF'
      }
      try {
        await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/light/toggle_nightlight`, {
          state,
        })
        this.makeToast(toastTitle, 'CoffeeIcon', 'success', toastText)
      } catch (e) {
        console.error(e)
      }
    },
    updatedNightlightBrightnessSlider() {
      try {
        this.setNightlightBrightnessEmma()
      } catch (e) {
        console.error(e)
      }
    },
    /**
     * Sets night light brightness of emma to value set via slider (brightnessNightlight), presently 0-100
     * @returns {Promise<void>}
     */
    async setNightlightBrightnessEmma() {
      await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/light/set_brightness_night`, {
        brightness: this.brightnessNightLight,
      })
    },
  },
}
</script>

<style scoped>
</style>
