<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ $t('testalarm') }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-button
        block
        variant="danger"
        @click="triggerAlarmOnEmma"
      >
        {{ $t('triggerAlarm') }}
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardBody, BCardTitle, BCardHeader,
} from 'bootstrap-vue'

export default {
  name: 'EmergencyCall',
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
  },
  props: {
    selectedEmma: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async triggerAlarmOnEmma() {
      try {
        await this.$http.post(`/emmas/${this.selectedEmma.serial}/alarm/maindevice`, { state: 'activate' })
      } catch (e) {
        console.error(e)
      }
    },
    async triggerPingOnEmma() {
      try {
        await this.$http.post(`/emmas/${this.selectedEmma.serial}/audio/signal`, {})
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style scoped>

</style>
