<template>
  <b-card>
    <b-col>
      <!--      <b-row>-->
      <!--        <b-button-group class="w-100 mb-2">-->
      <!--          <b-button-->
      <!--            class="btn-success mr-1"-->
      <!--            @click="setMicrophoneInput(true)"-->
      <!--          >-->
      <!--            Microphone ON-->
      <!--          </b-button>-->
      <!--          <b-button-->
      <!--            class="btn-danger"-->
      <!--            @click="setMicrophoneInput(false)"-->
      <!--          >-->
      <!--            Microphone OFF-->
      <!--          </b-button>-->
      <!--        </b-button-group>-->
      <!--      </b-row>-->
      <b-row>
        <b-button-group class="w-100  mb-2">
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('termin erstellen')"
          >
            {{ $t('createAppointment') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('zeiten umstellen')"
          >
            {{ $t('changeTimes') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('nachricht verschicken')"
          >
            {{ $t('sendMessage') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('kontakt anrufen')"
          >
            {{ $t('callContact') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('medikamente einrichten')"
          >
            {{ $t('setUpMedication') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('trinken einrichten')"
          >
            {{ $t('setUpDrinking') }}
          </b-button>
        </b-button-group>
        <b-button-group class="w-100 mb-2">
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('wetter')"
          >
            {{ $t('weather') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('wie spaet ist es')"
          >
            {{ $t('whatsTheTime') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('wohnzimmer licht einschalten')"
          >
            {{ $t('livingroomLightOn') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('wohnzimmer licht ausschalten')"
          >
            {{ $t('livingroomLightOff') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('licht einschalten')"
          >
            {{ $t('lightOn') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmmaWithWakeword('licht ausschalten')"
          >
            {{ $t('lightOff') }}
          </b-button>
        </b-button-group>
      </b-row>
      <b-row>
        <b-button-group class="w-100  mb-2">
          <b-button
            class="mr-1"
            variant="dark"
            @click="sendToEmma('ja')"
          >
            {{ $t('yes') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="dark"
            @click="sendToEmma('nein')"
          >
            {{ $t('no') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="dark"
            @click="sendToEmma('medikamente')"
          >
            {{ $t('medication') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="dark"
            @click="sendToEmma('trinken')"
          >
            {{ $t('drink') }}
          </b-button>
        </b-button-group>
      </b-row>
      <b-row>
        <b-button-group class="w-100 mb-2">
          <b-button
            class="mr-1"
            variant="dark"
            @click="sendToEmma('morgens')"
          >
            {{ $t('inTheMorning') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="dark"
            @click="sendToEmma('mittags')"
          >
            {{ $t('atNoon') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="dark"
            @click="sendToEmma('abends')"
          >
            {{ $t('inTheEvening') }}
          </b-button>
        </b-button-group>
      </b-row>
      <b-row>
        <b-button-group class="w-100 mb-2">
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmma('neun uhr')"
          >
            9:00
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmma('zehn uhr dreissig')"
          >
            10:30
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmma('zwoelf uhr')"
          >
            12:00
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmma('dreizehn uhr dreissig')"
          >
            13:30
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmma('fuenfzehn uhr')"
          >
            15:00
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmma('sechzehn uhr dreissig')"
          >
            16:30
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmma('achtzehn uhr')"
          >
            18:00
          </b-button>
        </b-button-group>
      </b-row>
      <b-row>
        <b-button-group class="w-100 mb-2">
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmma('heute')"
          >
            {{ $t('today') }}
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="sendToEmma('morgen')"
          >
            {{ $t('morning') }}
          </b-button>
        </b-button-group>
      </b-row>
      <b-row>
        <b-button-group class="w-100 mb-2">
          <b-button
            class="mr-1"
            variant="danger"
            @click="sendToEmma('cancel')"
          >
            {{ $t('cancel') }}
          </b-button>
        </b-button-group>
      </b-row>
      <b-row>
        <b-col cols="8">
          <b-form-input
            v-model="text"
            :placeholder="$t('enterText')"
          />
        </b-col>
        <b-col cols="4">
          <b-button-group style="width: 100%">
            <b-button
              variant="dark"
              class="ml-3"
              @click="sendToEmma(text)"
            >
              {{ $t('sendText') }}
            </b-button>
            <b-button
              variant="dark"
              class="ml-3"
              @click="sendWakeword()"
            >
              {{ $t('sendHelloEmma') }}
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import {
  BCol, BRow, BCard, BButtonGroup, BButton, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'EmmaDebugButtons',
  components: {
    BCol, BRow, BCard, BButtonGroup, BButton, BFormInput,
  },
  props: {
    selectedEmma: {
      type: Object,
      required: true,
    },
    selectedEmmaSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      text: '',
    }
  },
  methods: {
    setMicrophoneInput(status) {
      const payload = { serial: this.selectedEmma.serial, state: status }
      this.$store.dispatch('emmasettings/setMicrophoneStatus', payload)
    },
    sendToEmma(text) {
      const payload = { serial: this.selectedEmma.serial, text }
      this.$store.dispatch('emmasettings/simulateVoiceInput', payload)
    },
    sendWakeword() {
      this.$store.dispatch('emmasettings/simulateWakeword', { serial: this.selectedEmma.serial })
    },
    sendToEmmaWithWakeword(text) {
      this.sendWakeword()
      const payload = { serial: this.selectedEmma.serial, text }
      this.$store.dispatch('emmasettings/simulateVoiceInput', payload)
    },
  },
}
</script>

<style scoped>

</style>
