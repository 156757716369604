<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <contact-person-configurator />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <alarm-center-number-configurator />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'

import ContactPersonConfigurator from '@/components/emma/calls/ContactPersonConfigurator.vue'
import AlarmCenterNumberConfigurator from '@/components/emma/calls/AlarmCenterNumberConfigurator.vue'

export default {
  name: 'EmmaCallDashboard',
  components: {
    BRow,
    BCol,
    AlarmCenterNumberConfigurator,
    ContactPersonConfigurator
  }
}
</script>

<style scoped>

</style>
