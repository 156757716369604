<template>
  <div>
    <div v-if="!error">
      <b-card class="flex-nowrap overflow-auto">
        <b-card-header>
          <b-card-title>
            {{ $t('pairedLocks') }}
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <b-table
            :fields="pairedDevicesFields"
            :items="nukiDevices"
            :busy="loadingPairedDevices"
            show-empty
            outlined
          >
            <template #cell(name)="row">
              <div>
                {{ row.item.name }} ({{ row.item.address }})
              </div>
            </template>
            <template #cell(status)="row">
              <div>
                {{ getLockState(row.item.lastKnownState.stateName) }}
              </div>
            </template>
            <template #cell(battery)="row">
              <div>
                <b-progress
                  v-model="row.item.lastKnownState.batteryChargeState"
                  show-progress
                  style="height: 25px"
                  max="100"
                />
              </div>
            </template>
            <template #cell(rssi)="row">
              <div>
                <b-button
                  disabled
                  block
                  :variant="getRssiVariant(row.item.rssi).variant"
                >
                  <div
                    class="alert-body text-center text-dark font-weight-bolder"
                  >
                    {{ getRssiVariant(row.item.rssi).title }}
                  </div>
                </b-button>
              </div>
            </template>
            <template #cell(actions)="row">
              <div>
                <b-button-group
                  style="width: 100%"
                >
                  <b-button
                    v-if="(row.item.lastKnownState.stateName !== 'UNLOCKED' || row.item.lastKnownState.stateName === 'UNLATCHED')"
                    class="mr-1"
                    variant="primary"
                    :disabled="commandInProgress"
                    @click="openDoor(row.item)"
                  >
                    {{ $t('open') }}
                  </b-button>
                  <b-button
                    v-if="(row.item.lastKnownState.stateName === 'UNLOCKED' || row.item.lastKnownState.stateName === 'UNDEFINED')"
                    class="mr-1"
                    variant="primary"
                    :disabled="commandInProgress"
                    @click="unlatchDoor(row.item)"
                  >
                    {{ $t('unlatch') }}
                  </b-button>
                  <b-button
                    v-if="row.item.lastKnownState.stateName !== 'LOCKED'"
                    class="mr-1"
                    variant="dark"
                    :disabled="commandInProgress"
                    @click="closeDoor(row.item)"
                  >
                    {{ $t('close') }}
                  </b-button>
                </b-button-group>
              </div>
            </template>
            <template #cell(remove)="row">
              <div>
                <b-button
                  variant="danger"
                  class="btn-icon ml-1"
                  @click="unpair(row.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </template>
            <template #table-busy>
              <b-skeleton class="mt-1" />
            </template>
            <template #empty>
              <div class="text-center">
                {{ $t('fewSecondsAfterPairingNukisWillShowUp') }}
              </div>
            </template>
          </b-table>
          <b-button
            variant="primary"
            class="mt-2"
            @click="getNukiList"
          >
            {{ $t('reload') }}
          </b-button>
        </b-card-body>
      </b-card>
      <b-card>
        <b-card-header>
          <b-card-title>
            {{ $t('nearbyLocks') }}
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <div>
            <b-table
              :fields="pairableDevicesFields"
              :items="pairableDevices"
              :busy="loadingPairableDevices"
              outlined
            >
              <template #cell(name)="row">
                <div>
                  {{ row.item.name }}
                </div>
              </template>
              <template #cell(rssi)="row">
                <b-button
                  disabled
                  block
                  :variant="getRssiVariant(row.item.rssi).variant"
                >
                  <div
                    class="alert-body text-center text-dark font-weight-bolder"
                  >
                    {{ getRssiVariant(row.item.rssi).title }}
                  </div>
                </b-button>
              </template>
              <template #cell(pair)="row">
                <div>
                  <b-button
                    v-if="pairing.address !== row.item.address"
                    :disabled="containsNukiDevice(row.item.address) || pairing.status"
                    block
                    variant="primary"
                    @click="pair(row.item)"
                  >
                    {{ containsNukiDevice(row.item.address) ? $t('nukiIsAlreadyPaired', {nuki: row.item.name}) : $t('pairNuki', {nuki: row.item.name}) }}
                  </b-button>
                  <b-button
                    v-else
                    disabled
                    block
                  >
                    {{ $t('pairingInProcess') }}
                  </b-button>
                </div>
              </template>
              <template #table-busy>
                <div
                  class="text-center text-primary my-2"
                >
                  {{ $t('searchForDevices') }}
                  <b-progress
                    v-model="loadingBarValue"
                    class="mt-1"
                    show-progress
                    animated
                    style="height: 25px"
                    max="100"
                  />
                </div>
              </template>
            </b-table>
            <b-button
              v-if="!loadingPairableDevices"
              variant="primary"
              class="mt-2"
              @click="scanNukis"
            >
              {{ $t('searchForNearbyNukis') }}
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div v-else>
      <b-card>
        <b-card-header>
          <b-card-title>
            {{ $t('nukiserviceOffline') }}
          </b-card-title>
          <b-card-sub-title>
            {{ $t('nukiServiceNotRunning') }}
          </b-card-sub-title>
        </b-card-header>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BTable,
  BProgress,
  BButtonGroup,
  BSkeleton,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'NukiManager',
  components: {
    BCard,
    BButton,
    BTable,
    BProgress,
    BButtonGroup,
    BSkeleton,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
  },
  data() {
    return {

      pairing: { status: false, address: undefined },
      selectedNuki: undefined,
      nukiDevices: [],
      pairableDevices: [],
      scanning: false,
      commandInProgress: false,
      loadingPairableDevices: false,
      loadingPairedDevices: false,
      loadingBarValue: 0,
      autoUpdate: undefined,
      error: true,
    }
  },
  computed: {
    selectedEmma() {
      return this.$store.state.emmasettings.selectedEmma
    },
    pairableDevicesFields() {
      return [
        { key: 'name', label: this.$t('name') },
        { key: 'address', label: this.$t('bluetoothAddress') },
        { key: 'rssi', label: this.$t('signalStrength') },
        { key: 'pair', label: this.$t('pair') }]
    },
    pairedDevicesFields() {
      return [
        { key: 'name', label: this.$t('name') },
        { key: 'status', label: this.$t('status') },
        { key: 'battery', label: this.$t('batteryStatus') },
        { key: 'rssi', label: this.$t('signalStrength') },
        { key: 'actions', label: this.$t('actions') },
        { key: 'remove', label: this.$t('remove') }]
    },
  },
  async mounted() {
    this.loadingPairedDevices = true
    await this.getNukiList()
    this.error = false
    this.loadingPairedDevices = false
    await this.scanNukis()
    this.autoUpdate = setInterval(await this.getNukiList, 1000)
  },
  beforeDestroy() {
    clearInterval(this.autoUpdate)
  },
  methods: {
    containsNukiDevice(address) {
      for (let i = 0; i < this.nukiDevices.length; i += 1) {
        if (this.nukiDevices[i].address === address) {
          return true
        }
      }
      return false
    },
    async getNukiList() {
      const nukiListResponse = await this.$http.get(`/emmas/${this.selectedEmma.serial}/smarthome/nuki/list`)
      this.nukiDevices = nukiListResponse.data
    },
    async scanNukis() {
      this.loadingPairableDevices = true
      this.startLoadingBar()
      this.pairableDevices = []
      const scanNukisResponse = await this.$http.post(`/emmas/${this.selectedEmma.serial}/smarthome/nuki/scan`)
      Object.keys(scanNukisResponse.data).forEach(nuki => {
        this.pairableDevices.push({
          name: nuki,
          address: scanNukisResponse.data[nuki].address,
          rssi: scanNukisResponse.data[nuki].rssi,
        })
      })
      this.loadingPairableDevices = false
    },
    pair(device) {
      try {
        this.pairing = { status: true, address: device.address }
        setTimeout(() => {
          this.pairing = { status: false, address: undefined }
        }, 15000)
        this.$http.post(`/emmas/${this.selectedEmma.serial}/smarthome/nuki/pair`, { address: device.address })
        // this.makeToast('Erfolgreich!', 'CheckSquareIcon', 'success', 'Türschloss wird gekoppelt!')
      } catch (e) {
        // this.makeToast('Fehler!', 'XIcon', 'danger', 'Türschloss konnte nicht gekoppelt werden!')
      }
    },
    async unpair(device) {
      try {
        await this.$http.post(`/emmas/${this.selectedEmma.serial}/smarthome/nuki/unpair`, { address: device.address })
        // this.makeToast('Erfolgreich!', 'CheckSquareIcon', 'success', 'Türschloss wird entkoppelt!')
      } catch (e) {
        // this.makeToast('Fehler!', 'XIcon', 'danger', 'Türschloss konnte nicht entkoppelt werden!')
      }
    },
    async openDoor(device) {
      this.commandInProgress = true
      const id = device.nukiId
      try {
        await this.$http.post(`/emmas/${this.selectedEmma.serial}/smarthome/nuki/open`, { id })
        // this.makeToast('Erfolgreich!', 'CheckSquareIcon', 'success', 'Befehl erfolgreich an Emma geschickt!')
      } catch (e) {
        console.log(e)
        // this.makeToast('Fehler!', 'XIcon', 'danger', 'Befehl konnte nicht an Emma geschickt werden!')
      }
      setTimeout(() => {
        this.commandInProgress = false
      }, 5000)
    },
    async closeDoor(device) {
      this.commandInProgress = true
      const id = device.nukiId
      try {
        await this.$http.post(`/emmas/${this.selectedEmma.serial}/smarthome/nuki/close`, { id })
      } catch (e) {
        console.log(e)
      }
      setTimeout(() => {
        this.commandInProgress = false
      }, 5000)
    },
    async unlatchDoor(device) {
      this.commandInProgress = true
      const id = device.nukiId
      console.log('Unlatching')
      try {
        await this.$http.post(`/emmas/${this.selectedEmma.serial}/smarthome/nuki/unlatch`, { id })
      } catch (e) {
        console.log(e)
      }
      setTimeout(() => {
        this.commandInProgress = false
      }, 5000)
    },
    startLoadingBar() {
      this.loadingBarValue = 0
      const counter = setInterval(() => {
        this.loadingBarValue += 1
        if (this.loadingBarValue >= 100) {
          clearInterval(counter)
        }
      }, 70)
    },
    getRssiVariant(rssi) {
      if (rssi > -65) {
        return { title: this.$t('excellent'), variant: 'success' }
      }
      if (rssi > -85) {
        return { title: this.$t('acceptable'), variant: 'warning' }
      }
      return { title: this.$t('bad'), variant: 'danger' }
    },
    beforeDestroy() {
      clearInterval(this.autoUpdate)
    },
    getLockState(state) {
      if (state === 'UNLOCKED') {
        return this.$t('lockOpen')
      }
      if (state === 'UNLOCKING') {
        return this.$t('lockOpening')
      }
      if (state === 'LOCKED') {
        return this.$t('lockLocked')
      }
      if (state === 'LOCKING') {
        return this.$t('lockLocking')
      }
      if (state === 'MOTOR_BLOCKED') {
        return this.$t('motorBlocked')
      }
      if (state === 'UNDEFINED') {
        return this.$t('unknown')
      }
      if (state === 'UNCALIBRATED') {
        return this.$t('notCalibrated')
      }
      return `${state}`
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
