<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $t('eventOn', {type: scope.type, expr: event.id ? 'bearbeiten': 'hinzufügen'}) }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit"
        >
          <b-form-group
            v-if="scope.type === 'emma'"
            :label="$t('serialNumber')"
            label-for="event-serial"
          >
            <b-form-input
              id="event-serial"
              v-model="scope.id"
              disabled
              trim
            />
          </b-form-group>
          <b-form-group
            :label="$t('title')"
            label-for="event-title"
          >
            <b-form-input
              id="event-title"
              v-model="event.title"
              autofocus
              trim
              :placeholder="$t('eventTitle')"
            />
          </b-form-group>
          <b-form-group
            :label="$t('startDate')"
            label-for="end-date"
          >
            <flat-pickr
              v-model="event.start"
              class="form-control"
              :config="{ enableTime: true, altInput: true, altFormat: 'd-m-Y H:i', dateFormat: 'Z', time_24hr: true, locale: {firstDayOfWeek: 1}}"
            />
          </b-form-group>
          <b-form-group
            v-if="!event.allday"
            :label="$t('endDate')"
            label-for="end-date"
          >
            <div
              :style="(dateValid) ? '' : 'box-shadow: 0px 0px 2px 2px #F97794'"
            >
              <flat-pickr
                v-model="event.end"
                class="form-control"
                :config="{ enableTime: true, altInput: true, altFormat: 'd-m-Y H:i', dateFormat: 'Z', time_24hr: true, locale: {firstDayOfWeek: 1}}"
              />

            </div>
          </b-form-group>
          <!-- All Day -->
          <b-form-group>
            <b-form-checkbox
              v-model="event.allday"
              name="check-button"
              switch
              inline
            >
              {{ $t('allDay') }}
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            :label="$t('voiceRecording')"
            label-for="event-audio"
          >
            <calendar-audio-handler
              if="event-audio"
              :is-event-handler-sidebar-active="isEventHandlerSidebarActive"
              :calender-event-audio-string="event.audio"
              @created-audio="addAudio"
              @removed-audio="removeAudio"
            />
          </b-form-group>
          <b-form-group
            :label="$t('description')"
            label-for="event-description"
          >
            <b-form-textarea
              id="event-description"
              v-model="event.description"
            />
          </b-form-group>
          <!-- Form Actions -->
          <div
            class="d-flex mt-2"
          >
            <div style="width: 100%">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="!dateValid || event.title === ''"
                variant="success"
                class="mr-2"
                type="submit"
                style="width: calc(50% - 1rem)"
              >
                {{ event.id ? 'Updaten' : 'Hinzufügen ' }}
              </b-button>
              <b-button
                v-if="!event.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="danger"
                style="width: calc(50% - 1rem)"
                @click="handleCancel"
              >
                {{ $t('cancel') }}
              </b-button>
              <b-button
                v-if="event.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="danger"
                style="width: calc(50% - 1rem)"
                @click="removeEvent(); hide();"
              >
                {{ $t('delete') }}
              </b-button>
            </div>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CalendarAudioHandler from '@/components/calendar/calendar-event-handler/CalendarAudioHandler.vue'

export default {
  name: 'CalendarEventHandler',
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea, BButton, flatPickr, CalendarAudioHandler,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    scope: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    dateValid() {
      return (new Date(this.event.end) - new Date(this.event.start)) >= 0 || this.event.allday
    },
  },
  watch: {
    event: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.start && oldVal.start) {
          const newDate = new Date(newVal.start)
          newDate.setHours(newDate.getHours() + 1)
          this.event.end = newDate.toISOString()
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    handleCancel() {
      this.$emit('cancelled')
    },
    async removeAudio() {
      this.event.audio = undefined
    },
    async addAudio(audio) {
      this.event.audio = await new Promise(resolve => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(audio)
      })
    },
    removeEvent() {
      this.event.scopeId = this.scope.id
      try {
        this.$store.dispatch('calendar/removeEmmaEvent', this.event)
        this.makeToast(`Termin ${this.event.title} erfolgreich gelöscht!`, '', 'CheckSquareIcon', 'success')
      } catch (e) {
        this.makeToast(`Termin ${this.event.title} konnte nicht gelöscht werden!`, '', 'XIcon', 'danger')
      }
    },
    async handleSubmit() {
      if (this.event.allday) {
        this.event.end = this.event.start
      }
      if (this.event.id) {
        try {
          this.event.scopeId = this.scope.id
          await this.$store.dispatch('calendar/updateEmmaEvent', this.event)
          this.makeToast(`Termin ${this.event.title} erfolgreich bearbeitet!`, '', 'CheckSquareIcon', 'success')
        } catch (e) {
          this.makeToast(`Termin ${this.event.title} konnte nicht bearbeitet werden!`, '', 'XIcon', 'danger')
        }
      } else {
        try {
          await this.$store.dispatch('calendar/addEmmaEvent', this.event)
          this.makeToast(`Termin ${this.event.title} erfolgreich erstellt!`, '', 'CheckSquareIcon', 'success')
        } catch (e) {
          this.makeToast(`Termin ${this.event.title} konnte nicht erstellt werden!`, '', 'XIcon', 'danger')
        }
      }
      this.$emit('submitted')
    },
    resetForm() {
      console.log('resetting')
    },
    makeToast(title, text, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
