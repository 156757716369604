<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>
          {{ $t('addNewZigbeeDevice') }}
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-form-row>
          <b-col
            cols="12"
            lg="3"
            class="mb-sm-1 mb-md-1 mb-lg-0"
          >
            <b-form-input
              v-model="newDeviceId"
              :placeholder="$t('id')"
            />
          </b-col>
          <b-col
            cols="12"
            lg="3"
            class="mb-sm-1 mb-md-1 mb-lg-0"
          >
            <b-form-input
              v-model="newDeviceFriendlyName"
              :placeholder="$t('name')"
            />
          </b-col>
          <b-col
            cols="12"
            lg="2"
            class="mb-sm-1 mb-md-1 mb-lg-0"
          >
            <b-form-select
              v-model="newDeviceType"
              :options="sensorTypeOptions"
            />
          </b-col>
          <b-col
            cols="12"
            lg="2"
            class="mb-sm-1 mb-md-1 mb-lg-0"
          >
            <b-form-select
              v-model="newDeviceAppliance"
              :options="sensorApplianceOptions"
            />
          </b-col>
          <b-col
            cols="12"
            lg="2"
            class="mb-sm-1 mb-md-1 mb-lg-0"
          >
            <b-button
              variant="primary"
              block
              :disabled="newDeviceId === '' || (newDeviceType === 'power' && !newDeviceAppliance)"
              @click="addDevice('zigbee')"
            >
              <div v-if="!editingDevice">
                {{ $t('add') }}
              </div>
              <div v-else>
                {{ $t('edit') }}
              </div>
            </b-button>
          </b-col>
        </b-form-row>
      </b-card-body>
    </b-card>

    <div :key="refreshKey">
      <b-card>
        <b-card-header>
          <b-card-title>
            {{ $t('connectedZigbeeDevices') }}
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <b-table
            v-if="zigbeeDevices.length !== 0"
            striped
            responsive
            hover
            :items="zigbeeDevices"
            :fields="fields"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
          >
            <template #cell(id)="data">
              <div>
                {{ data.item.id }}
              </div>
            </template>
            <template #cell(friendlyName)="data">
              <div v-if="data.item.friendlyName">
                {{ data.item.friendlyName }}
              </div>
              <div v-else>
                {{ $t('noNameSet') }}
              </div>
            </template>
            <template #cell(type)="data">
              {{ getSensorText(data.item.type) }}
            </template>
            <template #cell(appliance)="data">
              {{ getApplianceText(data.item.appliance) }}
            </template>
            <template #cell(group)="data">
              <div v-if="data.item.group !== undefined">
                {{ data.item.group }}
              </div>
              <div v-else>
                {{ $t('noGroupAdded') }}
              </div>
            </template>
            <template #cell(delete)="data">
              <b-button
                variant="danger"
                class="ml-auto"
                @click="removeDevice(data.item)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                />
              </b-button>
            </template>
          </b-table>
          <b-alert
            :show="zigbeeDevices.length === 0"
            variant="danger"
            class="mb-0"
          >
            <div class="alert-body text-center">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              {{ $t('noZigbeeDevicesConnected') }}
            </div>
          </b-alert>
        </b-card-body>
      </b-card>
    </div>

    <div>
      <b-row
        v-for="device in zigbeeDevices"
        :key="device.id"
      >
        <b-col>
          <generic-curve-graph
            :title="(device.friendlyName) ? device.friendlyName : device.id"
            :selected-sensor="(device.friendlyName) ? device.friendlyName : device.id"
            :selected-emma="selectedEmma"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BFormRow, BFormInput, BRow, BTable, BCardHeader, BCardTitle, BCardBody, BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GenericCurveGraph from '@/components/emma/graphs/GenericSensorCurveGraph.vue'
import optionManager from 'echarts/src/model/OptionManager'

export default {
  name: 'ZigbeeManager',
  components: {
    BAlert, BButton, BCard, BCol, BFormRow, BFormSelect, BFormInput, BRow, BTable, BCardHeader, BCardTitle, BCardBody, GenericCurveGraph,
  },
  data() {
    return {
      newDeviceId: '',
      newDeviceFriendlyName: '',
      newDeviceGroup: undefined,
      newDeviceType: null,
      newDeviceAppliance: null,

      refreshKey: 0,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: this.$t('id') },
        { key: 'friendlyName', label: this.$t('name') },
        { key: 'group', label: this.$t('group') },
        { key: 'type', label: this.$t('type') },
        { key: 'appliance', label: this.$t('device') },
        { key: 'delete', label: this.$t('delete') },
      ]
    },
    sensorTypeOptions() {
      return [
        { value: null, text: this.$t('noSensortype') },
        { value: 'power', text: this.$t('power') },
        { value: 'presence', text: this.$t('movement') },
      ]
    },
    sensorApplianceOptions() {
      return [
        { value: null, text: this.$t('noDevice') },
        { value: 'coffeemaker', text: this.$t('coffeemaker') },
        { value: 'washingmachine', text: this.$t('washingMachine') },
      ]
    },
    selectedEmma() {
      return this.$store.state.emmasettings.selectedEmma
    },
    editingDevice() {
      return Object.keys(this.mapping.devices).find(deviceId => deviceId === this.newDeviceId) !== undefined
    },
    userData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
    zigbeeDevices() {
      // eslint-disable-next-line no-unused-expressions
      this.refreshKey

      const devices = []
      for (let i = 0; i < Object.keys(this.mapping.devices).length; i += 1) {
        const device = Object.keys(this.mapping.devices)[i]
        if (Object.prototype.hasOwnProperty.call(this.mapping.devices, device)) {
          if (this.mapping.devices[device].connection === 'zigbee') {
            const zigbeeDevice = this.mapping.devices[device]
            zigbeeDevice.id = device
            devices.push(zigbeeDevice)
          }
        }
      }
      return devices
    },
    mapping() {
      return this.$store.state.smarthome.mapping
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('smarthome/getSmarthomeMapping', { serial: this.selectedEmma.serial })
    } catch (e) {
      await this.$store.commit('smarthome/resetMapping')
    }
  },
  methods: {
    optionManager,
    async addDevice(connection) {
      const device = {
        id: this.newDeviceId, connection, friendlyName: this.newDeviceFriendlyName,
      }
      if (this.newDeviceGroup !== undefined) {
        device.group = this.newDeviceGroup
      }
      if (this.newDeviceType !== null) {
        device.type = this.newDeviceType
        if (this.newDeviceType === 'power' && this.newDeviceAppliance !== null) {
          device.appliance = this.newDeviceAppliance
        }
      }
      try {
        await this.$store.dispatch('smarthome/addDevice', device)
        this.refreshKey = Math.floor(Math.random() * 1000)
        await this.$store.dispatch('smarthome/sendSmarthomeMapping', this.selectedEmma)
        this.makeToast('Erfolgreich!', 'CheckSquareIcon', 'success', `Gerät ${device.id} wurde gespeichert!`)
      } catch (e) {
        console.error(e)
        this.makeToast('Fehler!', 'XIcon', 'danger', `Gerät ${device.id} konnte nicht gespeichert werden!`)
      }
      this.newDeviceId = undefined
      this.newDeviceFriendlyName = undefined
      this.newDeviceGroup = undefined
      this.newDeviceType = null
      this.newDeviceAppliance = null
    },
    async removeDevice(device) {
      try {
        await this.$store.dispatch('smarthome/removeDevice', device)
        this.refreshKey = Math.floor(Math.random() * 1000)
        await this.$store.dispatch('smarthome/sendSmarthomeMapping', this.selectedEmma)
        this.makeToast('Erfolgreich!', 'CheckSquareIcon', 'success', `Gerät ${device.id} wurde gelöscht!`)
      } catch (e) {
        console.error(e)
        this.makeToast('Fehler!', 'XIcon', 'danger', `Gerät ${device.id} konnte nicht gelöscht werden!`)
      }
      this.newDeviceId = undefined
      this.newDeviceFriendlyName = undefined
      this.newDeviceGroup = undefined
      this.newDeviceType = null
      this.newDeviceAppliance = null
    },
    onRowSelected(row) {
      if (row.length === 1) {
        const item = row[0]

        this.newDeviceId = item.id
        this.newDeviceFriendlyName = item.friendlyName
        this.newDeviceType = item.type
        this.newDeviceAppliance = item.appliance

        if (item.group !== undefined) {
          this.newDeviceGroup = item.group
        }
      }
    },
    getSensorText(value) {
      for (let i = 0; i < this.sensorTypeOptions.length; i += 1) {
        if (this.sensorTypeOptions[i].value === value) {
          return this.sensorTypeOptions[i].text
        }
      }
      return 'Kein Sensortyp'
    },
    getApplianceText(value) {
      for (let i = 0; i < this.sensorApplianceOptions.length; i += 1) {
        if (this.sensorApplianceOptions[i].value === value) {
          return this.sensorApplianceOptions[i].text
        }
      }
      return 'Kein Gerät'
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style>

</style>
