<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ $t('saveName') }}
      </b-card-title>
      <b-card-sub-title class="mt-1">
        {{ $t('saveYourNameHere') }}
      </b-card-sub-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
        >
          <b-input-group>
            <b-form-input
              v-model="callName"
              :placeholder="$t('yourName')"
            />
            <b-input-group-append>
              <b-button
                sm
                variant="primary"
                @click="saveCallName"
              >
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BCardSubTitle,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AlarmclockManager',
  components: {
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BCardSubTitle,
  },
  data() {
    return {
      callName: '',
    }
  },
  mounted() {
    this.callName = this.$store.state.emmasettings.settings.identity.callName
  },
  methods: {
    async saveCallName() {
      try {
        await this.$store.dispatch('emmasettings/saveCallName', { callName: this.callName })
        this.makeToast('Name gespeichert!', 'CheckIcon', 'success', '')
      } catch (e) {
        this.makeToast('Name konnte nicht gespeichert werden!', 'XIcon', 'danger', '')
      }
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
