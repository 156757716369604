<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ $t('alarm') }}
      </b-card-title>
    </b-card-header>
    <b-card-body class="mt-2">
      <b-row>
        <b-col
          cols="12"
        >
          <!-- Time picker component -->
          <b-form-datepicker
            id="date-picker"
            v-model="selectedDate"
            :label-no-date-selected="$t('noDateSelected')"
            locale="de"
            type="time"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          cols="12"
        >
          <!-- Time picker component -->
          <b-form-input
            id="time-picker"
            v-model="selectedTime"
            type="time"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          class="d-flex align-items-center"
        >
          <!-- Start button -->
          <b-button
            :disabled="!selectedTime"
            block
            style="height: 100%"
            variant="primary"
            @click="startAlarmclock"
          >
            {{ $t('setAlarm') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardBody, BFormInput, BRow, BCol, BCardHeader, BCardTitle, BFormDatepicker,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AlarmclockManager',
  components: {
    BButton,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormDatepicker,
  },
  props: {
  },
  data() {
    return {
      selectedTime: undefined,
      selectedDate: undefined,
      labels: {
        de: {
          labelHours: 'Stunden',
          labelMinutes: 'Minuten',
          labelSeconds: 'Sekunden',
          labelIncrement: 'Erhöhen',
          labelDecrement: 'Verringern',
          labelSelected: 'Ausgewählte Zeit',
          labelNoTimeSelected: 'Keine Zeit ausgewählt',
        },
      },
    }
  },
  mounted() {
  },
  methods: {
    async startAlarmclock() {
      if (!this.selectedTime) {
        return
      }
      const now = new Date()
      let date
      if (this.selectedDate) {
        date = new Date(this.selectedDate)
      } else {
        date = new Date()
      }

      const selectedHours = Number.parseInt(this.selectedTime.split(':')[0], 10)
      const selectedMinutes = Number.parseInt(this.selectedTime.split(':')[1], 10)

      date.setHours(selectedHours)
      date.setMinutes(selectedMinutes)
      date.setSeconds(0)

      if (now.getTime() > date.getTime()) {
        this.makeToast('Wecker kann nicht in Vergangenheit gestellt werden', 'ClockIcon', 'danger', '')
        return
      }
      if (now.getTime() > date.getTime()) {
        this.makeToast('Wecker kann nicht in Vergangenheit gestellt werden', 'ClockIcon', 'danger', '')
        return
      }
      try {
        const response = await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/timer/dateTimer`, { date: date.toString() })
        if (response.status === 200) {
          this.makeToast('Wecker gestellt', 'ClockIcon', 'success', `Wecker für ${this.selectedTime.split(':')[0]}:${this.selectedTime.split(':')[1]} gestellt`)
        }
      } catch (e) {
        console.error(e)
      }
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
