<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>
          {{ $t('typeOfCall') }}
        </b-card-title>
        <b-card-sub-title
          v-if="status === true"
        >
          {{ $t('currentStatus') }} <b>{{ $t('gsm') }}</b>
        </b-card-sub-title>
        <b-card-sub-title
          v-if="status === false"
        >
          {{ $t('currentStatus') }} <b>VoIP</b>
        </b-card-sub-title>
      </b-card-header>
      <b-dropdown
        id="dropdown-1"
        :text="(status) ? 'GSM' : 'VoIP'"
        block
        menu-class="w-100"
        variant="primary"
      >
        <b-dropdown-item @click="sendToEmma(false)">
          VoIP
        </b-dropdown-item>
        <b-dropdown-item @click="sendToEmma(true)">
          {{ $t('gsm') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardSubTitle, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CallTypeToggle',
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      status: undefined,
    }
  },
  mounted() {
    this.status = this.$store.state.emmasettings.settings.modemAudioCall !== ''
  },
  methods: {
    async sendToEmma(modemAudioCall) {
      try {
        await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/config/modemAudioCall`, { modemAudioCall })
        if (modemAudioCall) {
          this.makeToast('Erfolg', 'CoffeeIcon', 'success', 'Anrufe via GSM')
          this.status = true
        } else {
          this.makeToast('Erfolg', 'CoffeeIcon', 'success', 'Anrufe via VoIP')
          this.status = false
        }
      } catch (e) {
        this.makeToast('Fehler', 'CoffeeIcon', 'danger', 'Anruftyp konnte nicht umgestellt werden!')
      }
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
