<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ title }}

        <feather-icon
          size="16"
          icon="RefreshCwIcon"
          @click="pullSensorData"
        />
      </b-card-title>
      <b-dropdown
        v-if="!loading"
        :text="dropdownTitle"
        variant="transparent"
        class="chart-dropdown"
        right
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item @click="selectedHours = 1; changeData();">
          {{ $t('lastHour') }}
        </b-dropdown-item>
        <b-dropdown-item @click="selectedHours = 12; changeData();">
          {{ $t('last12Hours') }}
        </b-dropdown-item>
        <b-dropdown-item @click="selectedHours = 24; changeData();">
          {{ $t('last24Hours') }}
        </b-dropdown-item>
        <b-dropdown-item @click="selectedHours = 48; changeData();">
          {{ $t('last48Hours') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-card-body v-if="sensorData.length !== 0">
      <chartjs-component-line-chart
        v-if="!loading"
        :key="id"
        :height="300"
        :data="chartData"
        :options="chartOptions"
        :plugins="plugins"
      />
      <div
        v-if="!loading"
        class="mt-1 w-100"
      >
        <b-button
          v-for="option in sensorOptions"
          :key="option"
          class="mr-1 ml-1"
          variant="primary"
          @click="selectedOption = option; changeData()"
        >
          {{ sensorOptionText(option) }}
        </b-button>
      </div>
      <div v-if="loading">
        <b-skeleton-img
          no-aspect
          height="250px"
        />
        <b-skeleton
          class="mt-1"
          type="input"
        />
      </div>
    </b-card-body>
    <b-card-body v-else>
      <div v-if="loading">
        <b-skeleton-img
          no-aspect
          height="250px"
        />
        <b-skeleton
          class="mt-1"
          type="input"
        />
      </div>
      <div v-else>
        {{ $t('noDataFoundForSensor') }}
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCardBody, BCard, BCardHeader, BCardTitle, BButton, BSkeletonImg, BSkeleton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { $themeColors, $chartColors } from '@themeConfig'
import ChartjsComponentLineChart from '../../charts/ChartjsComponentLineChart.vue'

export default {
  name: 'GenericSensorCurveGraph',
  components: {
    ChartjsComponentLineChart,
    BCardBody,
    BCard,
    BCardHeader,
    BSkeleton,
    BSkeletonImg,
    BCardTitle,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  props: {
    selectedEmma: {
      type: Object,
      required: true,
    },
    selectedSensor: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    measurement: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      id: 0,
      sensorData: [],
      sensorOptions: [],
      selectedOption: undefined,
      selectedHours: 1,
      chartData: undefined,
      today: new Date().getTime(),
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  computed: {
    chartOptions() {
      const yesterday = new Date().setTime(this.today - (this.selectedHours * 60 * 60 * 1000))

      return {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
          borderColor: $themeColors.p,
          borderWidth: 1,
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: $chartColors.grid_line_color,
                zeroLineColor: $chartColors.grid_line_color,
              },
              ticks: {
                min: yesterday,
                max: this.today,
                fontColor: $chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: $chartColors.labelColor,
              },
              gridLines: {
                display: true,
                color: $chartColors.grid_line_color,
                zeroLineColor: $chartColors.grid_line_color,
              },
            },
          ],
        },
        legend: {
          display: true,
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      }
    },
    dropdownTitle() {
      if (this.selectedHours === 12) {
        return 'Letzten 12 Stunden'
      }
      if (this.selectedHours === 24) {
        return 'Letzten 24 Stunden'
      }
      if (this.selectedHours === 48) {
        return 'Letzte 48 Stunden'
      }
      return 'Letzte Stunde'
    },
  },
  async mounted() {
    await this.pullSensorData()
  },
  methods: {
    async pullSensorData() {
      this.loading = true
      try {
        this.sensorData = await this.$http.get(`/emmas/${this.selectedEmma.serial}/statistics/sensor/?name=smarthome/${this.selectedSensor}&limit=10000`)
        this.sensorData = this.sensorData.data.slice(0, 10000)
        this.sensorData.reverse()
        this.selectedOption = this.measurement
        this.chartData = this.setChartData()
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    setChartData() {
      const list = []
      const sensorData = []
      if (this.sensorData[0]) {
        this.sensorOptions = []
        for (const sensorOption of Object.keys(JSON.parse(this.sensorData[0].value))) {
          if (typeof JSON.parse(this.sensorData[0].value)[sensorOption] === 'number') {
            this.sensorOptions.push(sensorOption)
          } else if (typeof JSON.parse(this.sensorData[0].value)[sensorOption] === 'boolean') {
            this.sensorOptions.push(sensorOption)
          }
        }
      }

      let lastTimestamp = 0

      for (let i = 0; i < this.sensorData.length; i += 1) {
        const currentTimestamp = new Date(this.sensorData[i].timestamp).getTime()
        if ((currentTimestamp - lastTimestamp) > 10000) {
          list.push(currentTimestamp)

          const value = JSON.parse(this.sensorData[i].value)
          if (this.selectedOption) {
            sensorData.push(value[this.selectedOption])
          } else {
            [this.selectedOption] = this.sensorOptions
            sensorData.push(value[this.sensorOptions[0]])
          }
          lastTimestamp = currentTimestamp
        }
      }

      const datasets = [{
        stepped: true,
        tension: 0,
        label: this.sensorOptionText(this.selectedOption),
        data: sensorData,
        borderColor: '#97c0d6',
        borderWidth: 3,
        pointStyle: 'circle',
        backgroundColor: '#97c0d6',
        fill: false,
        pointRadius: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 5,
        pointBorderColor: 'transparent',
        pointHoverBorderColor: $themeColors.white,
        pointHoverBackgroundColor: '#97c0d6',
        pointShadowOffsetX: 1,
        pointShadowOffsetY: 1,
        pointShadowBlur: 5,
        pointShadowColor: $chartColors.tooltipShadow,
      }]

      return {
        labels: list,
        datasets,
      }
    },
    async changeData() {
      this.loading = true
      try {
        this.chartData = this.setChartData()
        this.id += 1
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    sensorOptionText(sensor) {
      if (sensor === 'battery') {
        return 'Batteriestatus'
      }
      if (sensor === 'humidity') {
        return 'Luftfeuchtigkeit'
      }
      if (sensor === 'linkquality') {
        return 'Verbindungsstärke'
      }
      if (sensor === 'temperature') {
        return 'Temperatur'
      }
      if (sensor === 'voltage') {
        return 'Strom'
      }
      if (sensor === 'illuminance') {
        return 'Helligkeit'
      }
      if (sensor === 'illuminance_lux') {
        return 'Helligkeit (Lux)'
      }
      if (sensor === 'current') {
        return 'Strom'
      }
      if (sensor === 'power') {
        return 'Leistung'
      }
      if (sensor === 'voltage') {
        return 'Spannung'
      }
      if (sensor === 'occupancy') {
        return 'Bewegung'
      }
      if (sensor === 'tamper') {
        return 'Manipulation'
      }
      if (sensor === 'battery_low') {
        return 'Niedriger Batteriestatus'
      }
      return sensor
    },
  },
}
</script>

<style scoped>

</style>
