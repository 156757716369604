<template>
  <b-card>
    <b-card-header>
      <h2>
        {{ $t('emmaJobLog') }}
      </h2>
    </b-card-header>
    <b-row class="mb-1">
      <b-col
        lg="1"
        cols="4"
      >
        <b-button
          block
          :disabled="pageNumber === 1"
          @click="pageNumber -= 1"
        >
          <feather-icon
            icon="ArrowLeftIcon"
          />
        </b-button>
      </b-col>
      <b-col
        lg="1"
        cols="4"
      >
        <b-button
          variant="primary"
          block
          @click="updateLog"
        >
          <feather-icon
            icon="RefreshCwIcon"
          />
        </b-button>
      </b-col>
      <b-col
        lg="1"
        cols="4"
      >
        <b-button
          block
          :disabled="pageNumber === amountOfPages"
          @click="pageNumber += 1"
        >
          <feather-icon
            icon="ArrowRightIcon"
          />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          v-if="!loading"
          striped
          hover
          :items="paginatedItems"
          :fields="fields"
        />
        <b-spinner v-else />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BSpinner, BCardHeader, BTable, BButton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'EmmaDebugLog',
  components: {
    BCard, BSpinner, BCardHeader, BTable, BButton, BRow, BCol,
  },
  props: {
    selectedEmma: {
      type: Object,
      required: true,
    },
    selectedEmmaSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pageSize: 15,
      pageNumber: 1,
      items: [],
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'nextTrigger',
          sortable: true,
        },
      ],
      loading: false,
    }
  },
  computed: {
    amountOfPages() {
      return Math.ceil(this.items.length / this.pageSize)
    },
    paginatedItems() {
      return this.items.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize)
    },
  },
  watch: {
    selected() {
      this.updateLog()
    },
  },
  async mounted() {
    await this.updateLog()
  },
  methods: {
    async updateLog() {
      try {
        this.loading = true
        const result = await this.$http.get(`/emmas/${this.selectedEmma.serial}/status/jobs`)
        this.items = result.data
        for (let i = 0; i < this.items.length; i += 1) {
          this.items[i].nextTrigger = this.convertDateToReadableFormat(this.items[i].nextTrigger)
        }
      } catch (err) {
        console.error(err)
      }
      this.loading = false
    },
    convertDateToReadableFormat(dateString) {
      const date = new Date(dateString)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      const seconds = date.getSeconds().toString().padStart(2, '0')
      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
    },
  },
}
</script>

<style scoped>

</style>
