<template>
  <div>
    <b-row v-if="batteryState">
      <b-col
        :lg="batteryState.batteryConnected ? 4 : 6"
        cols="12"
      >
        <statistic-card-vertical
          class="full-height-card"
          :icon="batteryState.adapterConnected ? 'CheckIcon' : 'XIcon'"
          :color="batteryState.adapterConnected ? 'success' : 'danger'"
          :statistic="$t('powerCord')"
          :statistic-title="batteryState.adapterConnected ? $t('connected').toString() : $t('notConnected').toString()"
        />
      </b-col>
      <b-col
        :lg="batteryState.batteryConnected ? 4 : 6"
        cols="12"
      >
        <statistic-card-vertical
          class="full-height-card"
          :icon="batteryState.batteryConnected ? 'CheckIcon' : 'XIcon'"
          :color="batteryState.batteryConnected ? 'success' : 'danger'"
          :statistic="$t('batteryStatus')"
          :statistic-title="batteryState.batteryConnected ? $t('connected').toString() : $t('notConnected').toString()"
        />
      </b-col>
      <b-col
        v-if="batteryState.batteryConnected && !batteryState.batteryEmpty && !batteryState.batteryFull"
        lg="4"
        cols="12"
      >
        <statistic-card-vertical
          v-if="!batteryState.percentageBattery"
          class="full-height-card"
          :icon="batteryState.batteryCharging ? 'BatteryChargingIcon' : 'ZapOffIcon'"
          :color="batteryState.batteryCharging ? 'success' : 'danger'"
          :statistic="$t('chargingStatus')"
          :statistic-title="batteryState.batteryCharging ? $t('charging').toString() : $t('notCharging').toString()"
        />
        <statistic-card-vertical
          v-if="batteryState.percentageBattery"
          class="full-height-card"
          :icon="batteryState.batteryCharging ? 'BatteryChargingIcon' : 'ZapOffIcon'"
          :color="batteryState.batteryCharging ? 'success' : 'danger'"
          :statistic="batteryState.percentageBattery + '% geladen'"
          :statistic-title="batteryState.batteryCharging ? $t('charging').toString() : $t('notCharging').toString()"
        />
      </b-col>
      <b-col
        v-if="batteryState.batteryConnected && batteryState.batteryFull && !batteryState.batteryEmpty"
        lg="4"
        cols="12"
      >
        <statistic-card-vertical
          class="full-height-card"
          icon="BatteryIcon"
          color="success"
          :statistic="$t('batteryLevel')"
          :statistic-title="$t('fullyCharged').toString()"
        />
      </b-col>
      <b-col
        v-if="batteryState.batteryConnected && batteryState.batteryEmpty && !batteryState.batteryFull"
        lg="4"
        cols="12"
      >
        <statistic-card-vertical
          class="full-height-card"
          icon="ZapOffIcon"
          color="danger"
          :statistic="$t('batteryEmpty')"
          :statistic-title="$t('batteryEmptyWarning')"
          style="box-shadow: 0 0 10px 7px #F97794"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  name: 'BatteryStatus',
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  props: {
  },

  data() {
    return {
      batteryState: {},
    }
  },
  async mounted() {
    try {
      const response = await this.$http.get(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/hardware/battery`)
      this.batteryState = response.data
    } catch (e) {
      console.error(e)
    }
  },
}
</script>

<style scoped>

.full-height-card {
    height: calc(100% - 2rem);
}

</style>
