<template>
  <div>
    <b-row>
      <b-col>
        <emma-debug-log />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <emma-sensor-log
          :debug="true"
          :selected-emma="selectedEmma"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <emma-job-log
          :selected-emma-settings="selectedEmmaSettings"
          :selected-emma="selectedEmma"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <emergency-call :selected-emma="selectedEmma" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <app-collapse>
          <app-collapse-item
            :title="$t('testButtons')"
            class-name="mb-1"
          >
            <emma-debug-buttons
              :selected-emma-settings="selectedEmmaSettings"
              :selected-emma="selectedEmma"
            />
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EmmaDebugLog from '@/components/emma/debug/EmmaDebugLog.vue'
import EmmaSensorLog from '@/components/emma/debug/EmmaSensorLog.vue'
import EmmaJobLog from '@/components/emma/debug/EmmaJobLog.vue'
import EmergencyCall from '@/components/emma/calls/EmergencyCall.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import EmmaDebugButtons from '@/components/emma/debug/EmmaDebugButtons.vue'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'EmmaDebugDashboard',
  components: {
    BRow,
    BCol,
    EmmaDebugButtons,
    AppCollapseItem,
    AppCollapse,
    EmergencyCall,
    EmmaDebugLog,
    EmmaSensorLog,
    EmmaJobLog,
  },
  data() {
    return {
      jobs: [],
    }
  },
  computed: {
    selectedEmma() {
      return this.$store.state.emmasettings.selectedEmma
    },
    selectedEmmaSettings() {
      return this.$store.state.emmasettings.settings
    },
  },
  methods: {
    async getJobs() {
      try {
        const response = await this.$http.get(`/emmas/${this.selectedEmma.serial}/status/jobs`)
        this.jobs = response.data
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style scoped>

</style>
