<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>
          {{ $t('alarmType') }}
        </b-card-title>
        <b-card-sub-title
          v-if="alarmDevice === true"
        >
          {{ $t('currentStatus') }} <b>{{ $t('emergencyCall') }}</b>
        </b-card-sub-title>
        <b-card-sub-title
          v-if="alarmDevice === false"
        >
          {{ $t('currentStatus') }} <b>{{ $t('private') }}</b>
        </b-card-sub-title>
      </b-card-header>
      <b-dropdown
        id="dropdown-1"
        :text="(alarmDevice) ? $t('emergencyCall') : $t('private')"
        block
        menu-class="w-100"
        variant="primary"
      >
        <b-dropdown-item @click="sendToEmma(false)">
          {{ $t('private') }}
        </b-dropdown-item>
        <b-dropdown-item @click="sendToEmma(true)">
          {{ $t('emergencyCall') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardSubTitle, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AlarmDeviceToggle',
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      alarmDevice: undefined,
    }
  },
  mounted() {
    this.alarmDevice = this.$store.state.emmasettings.settings.alarmDevice
  },
  methods: {
    async sendToEmma(isAlarmDevice) {
      try {
        await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/config/alarmDevice`, { isAlarmDevice })
        if (isAlarmDevice) {
          this.makeToast('Erfolg', 'CoffeeIcon', 'success', 'Emma als Alarmgerät registriert! Anrufe gehen an Zentrale!')
          this.alarmDevice = true
        } else {
          this.makeToast('Erfolg', 'CoffeeIcon', 'success', 'Emma als Privat registriert! Anrufe gehen an Hauptkontakt!')
          this.alarmDevice = false
        }
      } catch (e) {
        this.makeToast('Fehler', 'CoffeeIcon', 'danger', 'Alarmtyp konnte nicht umgestellt werden!')
      }
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
