<template>
  <div>
    <b-row>
      <b-col
        md="4"
        cols="12"
      >
        <statistic-card-horizontal
          v-if="networkList.lan"
          icon="CheckIcon"
          color="success"
          :statistic="$t('lan')"
          :statistic-title="$t('available').toString()"
        />
        <statistic-card-horizontal
          v-else
          icon="XIcon"
          color="danger"
          :statistic="$t('lan')"
          :statistic-title="$t('notAvailable').toString()"
        />
      </b-col>
      <b-col
        md="4"
        cols="12"
      >
        <statistic-card-horizontal
          v-if="networkList.wifi"
          icon="CheckIcon"
          color="success"
          :statistic="$t('wifi')"
          :statistic-title="(ssid) ? 'SSID: ' + ssid : $t('available').toString()"
        />
        <statistic-card-horizontal
          v-else
          icon="XIcon"
          color="danger"
          :statistic="$t('wifi')"
          :statistic-title="$t('notAvailable').toString()"
        />
      </b-col>
      <b-col
        md="4"
        cols="12"
      >
        <statistic-card-horizontal
          v-if="networkList.gsm"
          icon="CheckIcon"
          color="success"
          :statistic="$t('gsm')"
          :statistic-title="$t('available').toString()"
        />
        <statistic-card-horizontal
          v-else
          icon="XIcon"
          color="danger"
          :statistic="$t('gsm')"
          :statistic-title="$t('notAvailable').toString()"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  name: 'AvailableNetworks',
  components: {
    StatisticCardHorizontal, BRow, BCol,
  },
  props: {
    availableNetworks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ssid: undefined,
    }
  },
  computed: {
    networkList() {
      const networks = { lan: false, wifi: false, gsm: false }
      if (this.availableNetworks.filter(network => network.name === 'eth0').length === 1) {
        networks.lan = true
      }
      if (this.availableNetworks.filter(network => network.name === 'wlan0').length === 1) {
        networks.wifi = true
      }
      if (this.availableNetworks.filter(network => network.name === 'ppp0').length === 1) {
        networks.gsm = true
      }
      return networks
    },
  },
  async mounted() {
    if (this.availableNetworks.filter(network => network.name === 'wlan0').length === 1) {
      await this.getActiveWifi()
    }
  },
  methods: {
    async getActiveWifi() {
      try {
        const response = await this.$http.get(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/config/wifi/ssid`)
        this.ssid = response.data.wifiSsid
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style scoped></style>
