<template>
  <div>
    <div v-if="!loading">
      <b-card>
        <b-card-header>
          <h2><b>1: </b>{{ $t('hueIpSettings') }}</h2>
        </b-card-header>
        <b-card-body>
          <b-row class="mb-1">
            <b-col>
              <h5>
                {{ $t('howToGetIpFromHue') }}
              </h5>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <b>1: </b>{{ $t('userBrokerServerProcess') }} <a
                href="https://discovery.meethue.com"
                target="_blank"
              >https://discovery.meethue.com</a> {{ $t('visit') }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <b>2: </b>{{ $t('hueAppMethod') }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <b>3: </b>{{ $t('accessRouterToLookForHue') }}
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <b>4: </b>{{ $t('usMdnsForHue') }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-input
                v-model="hueBridgeIp"
                placeholder="127.0.0.1"
                :state="validIp"
              />
            </b-col>
            <b-col cols="6">
              <b-button
                block
                variant="primary"
                :disabled="!validIp"
                @click="saveIp"
              >
                {{ $t('saveIP') }}
              </b-button>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>
      <b-card v-if="hueStatus.ip">
        <b-card-header>
          <h2><b>2: </b>{{ $t('pairEmmaWithHue') }}</h2>
        </b-card-header>
        <b-card-body>
          <b-row class="mb-1">
            <b-col>
              <h5>
                {{ $t('ensureEmmaAndHueInSameNetwork') }}
              </h5>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="12">
              <b-button
                block
                variant="primary"
                :disabled="pairing"
                @click="getToken"
              >
                {{ $t('pairEmma') }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-alert
                :variant="(hueStatus.token) ? 'success' : 'danger'"
                show
              >
                <div
                  v-if="hueStatus.token"
                  class="alert-body text-center text-success text-darken-4"
                >
                  {{ $t('emmaPairedSuccessfully') }}
                </div>
                <div
                  v-else
                  class="p-1 text-center text-danger text-darken-4"
                >
                  {{ $t('emmaNotPairedWithHueBridge') }}
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <div v-if="hueStatus.ip && hueStatus.token">
        <b-card v-if="!loadingLights">
          <b-card-header>
            <h2><b>3: </b> {{ $t('operateLights') }}</h2>
          </b-card-header>
          <b-card-body>
            <div
              v-if="Object.keys(hueDevices).length !== 0"
            >
              <div
                v-for="(device, id) in hueDevices"
                :key="device.uniqueid"
              >
                <hr>
                <b-row>
                  <b-col
                    md="6"
                    cols="12"
                    class="light-button"
                  >
                    <div
                      style="position: relative; top: 20%;"
                    >
                      <b>{{ device.name }}</b>
                      ({{ device.productname }})

                      <feather-icon
                        v-if="!device.state.reachable"
                        :id="'device-reachable-' + device.name"
                        class="text-danger"
                        icon="InfoIcon"
                        size="21"
                      />
                    </div>

                    <b-tooltip
                      triggers="hover"
                      :target="'device-reachable-' + device.name"
                      :title="$t('lightPotentiallyNotReachableByHueBridge')"
                      :delay="{ show: 500, hide: 50 }"
                    />
                  </b-col>
                  <b-col
                    md="3"
                    cols="12"
                  >
                    <b-button
                      variant="primary"
                      class="light-button"
                      block
                      @click="setLight(id, true)"
                    >
                      {{ $t('turnOnLight') }}
                    </b-button>
                  </b-col>
                  <b-col
                    md="3"
                    cols="12"
                  >
                    <b-button
                      variant="dark"
                      block
                      @click="setLight(id, false)"
                    >
                      {{ $t('turnOffLight') }}
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <hr>
            </div>
            <div v-else>
              <b-alert
                show
                variant="danger"
              >
                <div
                  class="alert-body text-center text-danger text-darken-4"
                >
                  <b>{{ $t('unableToGetDevicesFromHueBridge') }}</b>
                  <br>
                  {{ $t('ensureHueIsOnlineAndInSameNetwork') }}
                </div>
              </b-alert>
            </div>
            <b-row class="mt-1">
              <b-col>
                <b-button
                  block
                  variant="primary"
                  @click="getLights"
                >
                  {{ $t('reloadDevices') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card v-else>
          <b-card-header>
            <h2><b>3: </b> {{ $t('operateLights') }}</h2>
          </b-card-header>
          <b-card-body>
            <b-skeleton
              animation="wave"
              width="85%"
            />
            <b-skeleton
              animation="wave"
              width="55%"
            />
            <b-skeleton
              animation="wave"
              width="70%"
            />
            <b-skeleton
              animation="wave"
              width="85%"
            />
            <b-skeleton
              animation="wave"
              width="65%"
            />
            <b-skeleton
              animation="wave"
              width="70%"
            />
          </b-card-body>
        </b-card>
      </div>
    </div>
    <b-card v-else>
      <b-skeleton
        animation="wave"
        width="85%"
      />
      <b-skeleton
        animation="wave"
        width="55%"
      />
      <b-skeleton
        animation="wave"
        width="70%"
      />
      <b-skeleton
        animation="wave"
        width="85%"
      />
      <b-skeleton
        animation="wave"
        width="65%"
      />
      <b-skeleton
        animation="wave"
        width="70%"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormInput, BRow, BCol, BButton, BSkeleton, BCardHeader, BCardBody, BAlert, BTooltip,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'HueManager',
  components: {
    BCard,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BSkeleton,
    BCardHeader,
    BCardBody,
    BAlert,
    BTooltip,
  },
  data() {
    return {
      loading: false,
      loadingLights: false,
      hueStatus: { ip: false, token: false },
      hueBridgeIp: '',
      hueDevices: {},
      pairing: false,
    }
  },
  computed: {
    selectedEmma() {
      return this.$store.state.emmasettings.selectedEmma
    },
    validIp() {
      return !!(this.hueBridgeIp.match('^(?:[0-9]{1,3}\\.){3}[0-9]{1,3}$')
          || this.hueBridgeIp.match('(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]'
              + '{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:'
              + '[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]'
              + '{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4})'
              + '{0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|'
              + '(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.)'
              + '{3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))'))
    },
  },
  async mounted() {
    try {
      this.loading = true
      const hueStatusResult = await this.$http.get(`/emmas/${this.selectedEmma.serial}/smarthome/hue/getStatus`)
      if (hueStatusResult.data.ip) {
        await this.getIp()
        if (this.hueBridgeIp !== '') {
          this.hueStatus.ip = true
        }
      }
      if (hueStatusResult.data.token) {
        this.hueStatus.token = true
        await this.getLights()
      }
    } catch (err) {
      console.error(err)
    }
    this.loading = false
  },
  methods: {
    async getIp() {
      const hueBridgeIpResult = await this.$http.get(`/emmas/${this.selectedEmma.serial}/smarthome/hue/getIp`)
      this.hueBridgeIp = hueBridgeIpResult.data.ip
    },
    async setLight(id, state) {
      const payload = { id, settings: { on: state } }
      try {
        await this.$http.post(`/emmas/${this.selectedEmma.serial}/smarthome/hue/setLight`, payload)
        if (state) {
          this.makeToast('Licht eingeschalten!', 'CheckSquareIcon', 'success', '')
        } else {
          this.makeToast('Licht ausgeschalten!', 'CheckSquareIcon', 'success', '')
        }
      } catch (e) {
        if (state) {
          this.makeToast('Fehler!', 'XIcon', 'danger', 'Licht konnte nicht eingeschalten werden!')
        } else {
          this.makeToast('Fehler!', 'XIcon', 'danger', 'Licht konnte nicht ausgeschalten werden!')
        }
      }
    },
    async getLights() {
      this.loadingLights = true
      try {
        const getDevicesResult = await this.$http.get(`/emmas/${this.selectedEmma.serial}/smarthome/hue/getLights`)
        this.hueDevices = getDevicesResult.data
      } catch (e) {
      }
      this.loadingLights = false
    },
    async saveIp() {
      if (this.validIp) {
        try {
          await this.$http.post(`/emmas/${this.selectedEmma.serial}/smarthome/hue/setIp`, { ip: this.hueBridgeIp })
          this.makeToast('Erfolgreich!', 'CheckSquareIcon', 'success', 'IP wurde gespeichert!')
          const hueStatusResult = await this.$http.get(`/emmas/${this.selectedEmma.serial}/smarthome/hue/getStatus`)
          this.hueStatus = hueStatusResult.data
        } catch (e) {
          this.makeToast('Fehler!', 'XIcon', 'danger', 'IP konnte nicht gespeichert werden!')
        }
      } else {
        this.makeToast('Fehler!', 'XIcon', 'danger', 'Bitte überprüfe deine Eingabe!')
      }
    },
    async getToken() {
      try {
        this.pairing = true
        const getTokenResult = await this.$http.get(`/emmas/${this.selectedEmma.serial}/smarthome/hue/getTokenFromBridge`)
        this.pairing = false
        if (getTokenResult.data.error) {
          if (getTokenResult.data.type === 101) {
            this.makeToast('Fehler!', 'XIcon', 'danger', 'Knopf auf der Bridge wurde nicht gedrückt!')
          }
        } else if (!getTokenResult.data.error) {
          this.makeToast('Erfolgreich!', 'CheckSquareIcon', 'success', 'Emma wurde gekoppelt!')
          const hueStatusResult = await this.$http.get(`/emmas/${this.selectedEmma.serial}/smarthome/hue/getStatus`)
          this.hueStatus = hueStatusResult.data
          await this.getLights()
        }
      } catch (e) {
        this.makeToast('Fehler!', 'XIcon', 'danger', 'Emma konnte nicht gekoppelt werden!')
        this.pairing = false
      }
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>
@media (max-width: 768px) {
  .light-button {
    margin-bottom: 1rem;
  }
}
</style>
