<template>
  <div>
    <b-card>
      <b-button
        v-b-modal.add-emma-contact-modal
        class="mb-1"
        variant="primary"
      >
        {{ $t('addNewContact') }}
      </b-button>
      <a
        class="ml-1 mb-1 btn btn-primary"
        :href="'mailto:' + $store.state.emmasettings.selectedEmma.serial + '@e-mma.at'"
      >
        {{ $t('sendEmailToEmma') }}
      </a>
      <contact-person-table />
    </b-card>
    <add-emma-contact-modal />
  </div>
</template>

<script>
import {
  BButton, BCard
} from 'bootstrap-vue'

import ContactPersonTable from '@/components/emma/tables/ContactPersonTable.vue'
import AddEmmaContactModal from '@/components/forms/AddEmmaContactModal.vue'

export default {
  name: 'ContactPersonConfigurator',
  components: {
    BButton, BCard, ContactPersonTable, AddEmmaContactModal
  }
}
</script>

<style scoped>

</style>
