<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ $t('readingLight') }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col>
          <b-input-group>
            <b-input-group-prepend>
              <b-button-group>
                <b-button
                  variant="primary"
                  @click="toggleReadinglightStatus(true)"
                >
                  <feather-icon
                    size="14"
                    icon="ZapIcon"
                  />
                </b-button>
                <b-button
                  variant="dark"
                  @click="toggleReadinglightStatus(false)"
                >
                  <feather-icon
                    size="14"
                    icon="ZapOffIcon"
                  />
                </b-button>
              </b-button-group>
            </b-input-group-prepend>
            <b-form-input
              id="bg-opacity"
              v-model="brightnessReadingLight"
              style="align-items: center"
              debounce="100"
              type="range"
              number
              min="1"
              max="100"
              step="1"
              @update="updatedReadinglightBrightnessSlider"
            />
            <b-input-group-append
              is-text
              class="text-monospace"
            >
              {{ brightnessReadingLight }}
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="6">
          <label for="start-time-picker">{{ $t('turnOnAt') }}</label>
          <b-form-input
            id="start-time-picker"
            v-model="selectedStartTime"
            type="time"
            placeholder="HH:mm"
          />
        </b-col>
        <b-col cols="6">
          <label for="end-time-picker">{{ $t('turnOfAt') }}</label>
          <b-form-input
            id="end-time-picker"
            v-model="selectedEndTime"
            type="time"
            placeholder="HH:mm"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mt-1"
          style="text-align: center"
          cols="12"
        >
          <b-form-checkbox-group
            v-model="selectedDays"
            class="w-100"
          >
            <b-form-checkbox
              size="lg"
              value="mon"
            >
              {{ $t('mondayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="tue"
            >
              {{ $t('tuesdayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="wed"
            >
              {{ $t('wednesdayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="thu"
            >
              {{ $t('thursdayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="fri"
            >
              {{ $t('fridayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="sat"
            >
              {{ $t('saturdayShort') }}
            </b-form-checkbox>
            <b-form-checkbox
              size="lg"
              value="sun"
            >
              {{ $t('sundayShort') }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'

export default {
  name: 'ReadingLightManager',
  components: {
    BButtonGroup,
    BInputGroupPrepend,
    BButton,
    BCard,
    BCardBody,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  props: {
  },
  data() {
    return {
      brightnessReadingLight: 0,
      selectedStartTime: undefined,
      selectedEndTime: undefined,
      selectedDays: undefined,
    }
  },
  computed: {
    validTimes() {
      return (this.selectedStartTime && this.selectedEndTime)
    },
  },
  watch: {
    async selectedDays(newVal, oldVal) {
      if (newVal !== undefined && oldVal !== undefined) {
        await this.buildAndSendPayload()
      }
    },
    async selectedStartTime(newVal, oldVal) {
      if (newVal !== undefined && oldVal !== undefined) {
        await this.buildAndSendPayload()
      }
    },
    async selectedEndTime(newVal, oldVal) {
      if (newVal !== undefined && oldVal !== undefined) {
        await this.buildAndSendPayload()
      }
    },
  },
  mounted() {
    this.brightnessReadingLight = this.$store.state.emmasettings.settings.lights.reading.level
    this.selectedStartTime = this.$store.state.emmasettings.settings.lights.reading.startTime || '21:00'
    this.selectedEndTime = this.$store.state.emmasettings.settings.lights.reading.endTime || '07:00'
    this.selectedDays = this.$store.state.emmasettings.settings.lights.reading.days || []
  },
  methods: {
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
    async toggleReadinglightStatus(enabled) {
      let state
      let toastText = ''
      let toastTitle = ''
      if (enabled) {
        toastTitle = 'Licht ein'
        toastText = 'Leselicht erfolgreich eingeschalten'
        state = 'ON'
      } else {
        toastTitle = 'Licht aus'
        toastText = 'Leselicht erfolgreich ausgeschalten'
        state = 'OFF'
      }

      try {
        await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/light/toggle_readinglight`, {
          serial: this.$store.state.emmasettings.selectedEmma.serial,
          state,
        })
        this.makeToast(toastTitle, 'CoffeeIcon', 'success', toastText)
      } catch (e) {
        console.error(e)
      }
    },
    async buildAndSendPayload() {
      const payload = {
        startTime: this.selectedStartTime, endTime: this.selectedEndTime, days: this.selectedDays,
      }
      await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/light/schedule_readinglight`, payload)
    },
    /**
     * Gets called whenever readinglight brightness slider is moved (after debounce)
     */
    updatedReadinglightBrightnessSlider() {
      try {
        this.setReadinglightBrightnessEmma()
      } catch (e) {
        console.error(e)
      }
    },
    async setReadinglightBrightnessEmma() {
      await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/light/set_brightness_reading`, {
        brightness: this.brightnessReadingLight,
      })
    },
  },
}

</script>

<style scoped>

</style>
