<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ $t('gsmAlarmNumber') }}
      </b-card-title>
      <b-card-sub-title class="mt-1">
        {{ $t('gsmCallThisNumberCalled') }}
      </b-card-sub-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          lg="9"
          cols="12"
          class="mb-1 mb-lg-0"
        >
          <b-form-input
            v-model="alarmCenterNumber"
          />
        </b-col>
        <b-col
          lg="3"
          cols="12"
        >
          <b-button
            block
            variant="primary"
            @click="saveNumber"
          >
            {{ $t('save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardBody, BRow, BCol, BCardHeader, BCardTitle, BFormInput, BCardSubTitle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AlarmCenterNumberConfigurator',
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BCardSubTitle,
  },
  data() {
    return {
      alarmCenterNumber: '',
    }
  },
  mounted() {
    this.alarmCenterNumber = this.$store.state.emmasettings.settings.alarmCenterNumber
  },
  methods: {
    validateNumber() {
      const regex = /^00\d*$/
      return regex.test(this.alarmCenterNumber)
    },
    async saveNumber() {
      if (this.validateNumber()) {
        try {
          await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/config/alarmCenterNumber/`, { alarmCenterNumber: this.alarmCenterNumber })
          this.makeToast('Nummer gespeichert!', 'CheckSquareIcon', 'success', '')
        } catch (e) {
          this.makeToast('Nummer konnte nicht gespeichert werden!', 'XIcon', 'danger', '')
        }
      } else {
        this.makeToast('Ungültige Nummer!', 'XIcon', 'danger', '')
      }
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
