<template>
  <div
    class="flex-nowrap overflow-auto"
  >
    <b-card
      style="min-width: 500px"
    >
      <b-card-header>
        <h3 class="font-weight-bolder">
          {{ $t('connectionStatus') }}
        </h3>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            :class="(activeConnection !== undefined) ?
              'align-items-center text-center horizontal_dotted_line_success_after' :
              'align-items-center text-center horizontal_dotted_line_failure_after'"
          >
            <b-avatar
              rounded
              class="ml-1 mr-1"
              size="100"
              variant="primary"
            >
              <b-row>
                <b-col>
                  <feather-icon
                    icon="BoxIcon"
                    size="60"
                    color="#FFFFFF"
                  />
                </b-col>
              </b-row>
            </b-avatar>
          </b-col>
          <b-col
            :class="(activeConnection !== undefined) ?
              'align-items-center text-center horizontal_dotted_line_success_before_and_after' :
              'align-items-center text-center horizontal_dotted_line_failure_before_and_after'"
          >
            <div v-if="activeConnection !== undefined">
              <b-avatar
                class="ml-1 mr-1"
                size="70"
                variant="success"
              >
                <b-row>
                  <b-col>
                    <feather-icon
                      v-if="activeConnection === 'eth0'"
                      icon="RadioIcon"
                      size="45"
                      color="#FFFFFF"
                    />
                    <feather-icon
                      v-if="activeConnection === 'wlan0'"
                      icon="WifiIcon"
                      size="45"
                      color="#FFFFFF"
                    />
                    <feather-icon
                      v-if="activeConnection === 'ppp0'"
                      icon="BarChartIcon"
                      size="45"
                      color="#FFFFFF"
                    />
                  </b-col>
                </b-row>
              </b-avatar>
            </div>
            <div v-if="activeConnection === undefined">
              <b-avatar
                class="ml-1 mr-1"
                size="70"
                variant="danger"
              >
                <b-row>
                  <b-col>
                    <feather-icon
                      icon="HelpCircleIcon"
                      size="45"
                      color="#FFFFFF"
                    />
                  </b-col>
                </b-row>
              </b-avatar>
            </div>
          </b-col>
          <b-col
            :class="(activeConnection !== undefined) ?
              'align-items-center text-center horizontal_dotted_line_success_before' :
              'align-items-center text-center horizontal_dotted_line_failure_before'"
          >
            <b-avatar
              rounded
              class="ml-1 mr-1"
              size="100"
              variant="primary"
            >
              <b-row>
                <b-col>
                  <feather-icon
                    icon="ServerIcon"
                    size="60"
                    color="#FFFFFF"
                  />
                </b-col>
              </b-row>
            </b-avatar>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h1 class="font-weight-normal">
              {{ $t('emma') }}
            </h1>
          </b-col>
          <b-col
            class="text-center"
          >
            <h3 class="font-weight-normal">
              {{ activeConnectionText }}
            </h3>
          </b-col>
          <b-col class="text-right">
            <h1 class="font-weight-normal">
              {{ $t('server') }}
            </h1>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BAvatar, BCardHeader, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'EmmaNetworkChart',
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardHeader,
    BCardBody,
  },
  props: {
    activeConnection: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    activeConnectionText() {
      if (this.activeConnection === undefined) {
        return this.$t('connectionStatusUnknown')
      }
      if (this.activeConnection === 'wlan0') {
        return this.$t('wifi')
      }
      if (this.activeConnection === 'eth0') {
        return this.$t('lan')
      }
      if (this.activeConnection === 'ppp0') {
        return this.$t('gsm')
      }
      return this.$t('noConnection')
    },
  },
}
</script>

<style scoped>
.horizontal_dotted_line_success_before {
  display: flex;
  padding: 5px;
}
.horizontal_dotted_line_success_before:before {
  border-bottom: 8px dotted  #28c76f;
  content: '';
  flex: 1;
}
.horizontal_dotted_line_success_after {
  display: flex;
  padding: 5px;
}
.horizontal_dotted_line_success_after:after {
  border-bottom: 8px dotted  #28c76f;
  content: '';
  flex: 1;
}
.horizontal_dotted_line_success_before_and_after {
  display: flex;
  padding: 5px;
}
.horizontal_dotted_line_success_before_and_after:before {
  border-bottom: 8px dotted  #28c76f;
  content: '';
  flex: 1;
}
.horizontal_dotted_line_success_before_and_after:after {
  border-bottom: 8px dotted  #28c76f;
  content: '';
  flex: 1;
}
.horizontal_dotted_line_failure_before {
  display: flex;
  padding: 5px;
}
.horizontal_dotted_line_failure_before:before {
  border-bottom: 8px dotted  #ea5455;
  content: '';
  flex: 1;
}
.horizontal_dotted_line_failure_after {
  display: flex;
  padding: 5px;
}
.horizontal_dotted_line_failure_after:after {
  border-bottom: 8px dotted  #ea5455;
  content: '';
  flex: 1;
}
.horizontal_dotted_line_failure_before_and_after {
  display: flex;
  padding: 5px;
}
.horizontal_dotted_line_failure_before_and_after:before {
  border-bottom: 8px dotted  #ea5455;
  content: '';
  flex: 1;
}
.horizontal_dotted_line_failure_before_and_after:after {
  border-bottom: 8px dotted  #ea5455;
  content: '';
  flex: 1;
}

</style>
