<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ $t('outputVolume') }}
      </b-card-title>
    </b-card-header>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <b-input-group>
        <b-form-input
          id="bg-opacity"
          v-model="volumeSpeakers"
          type="range"
          number
          min="0"
          max="100"
          step="1"
          @mouseup="setSpeakerVolumeEmma"
        />
        <b-input-group-append
          is-text
          class="text-monospace"
        >
          {{ volumeSpeakers }}
        </b-input-group-append>
        <b-input-group-append>
          <b-button
            sm
            variant="primary"
            class="btn-icon"
            @click="pingEmma"
          >
            <feather-icon icon="BellIcon" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardBody, BFormInput, BInputGroup, BInputGroupAppend, BCardHeader, BCardTitle
} from 'bootstrap-vue'

export default {
  name: 'OutputSoundManager',
  components: {
    BButton,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BCardBody,
    BFormInput,
    BCardHeader,
    BCardTitle
  },
  props: {
  },
  data() {
    return {
      volumeSpeakers: 0
    }
  },
  mounted() {
    this.volumeSpeakers = this.$store.state.emmasettings.settings.volume.level
  },
  methods: {
    /**
     * Sends a ping to the emma serial number.
     * Route: Frontend -> Backend -> MQTT Bridge -> Emma
     * @returns {Promise<void>}
     */
    async pingEmma() {
      await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/audio/signal`)
    },
    /**
     * Sets volume of emma to value set via slider
     * @returns {Promise<void>}
     */
    async setSpeakerVolumeEmma() {
      await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/audio/set_volume`, {
        volume: this.volumeSpeakers
      })
    }
  }

}
</script>

<style scoped>

</style>
