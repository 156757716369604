<template>
  <b-modal

    id="add-emma-contact-modal"
    :no-close-on-backdrop="true"
    cancel-variant="outline-secondary"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    centered
    :title="$t('addContact')"
    @hidden="resetModal"
    @show="loadData"
    @ok="handleSubmit"
  >
    <b-form>
      <b-form-group>
        <label for="name"> <h6>{{ $t('name') }}</h6> </label>
        <b-form-input
          id="name"
          v-model="name"
          :placeholder="$t('name')"
          class="mb-1"
        />
        <label for="email"> <h6>{{ $t('email') }}</h6> </label>
        <b-form-input
          id="email"
          v-model="email"
          placeholder="email@addresse.com"
          class="mb-1"
        />
        <label for="phone"> <h6>{{ $t('phoneNumber') }}</h6> </label>
        <b-form-input
          id="phone"
          v-model="phoneNumber"
          :state="numberValid"
          placeholder="+436601234567"
          class="mb-1"
        />
        <div v-if="contactList.length !== 0">
          <label for="maincontactCheckbox"> <h6>{{ $t('mainContact') }}</h6> </label>
          <b-form-checkbox
            id="maincontactCheckbox"
            v-model="mainContact"
          />
        </div>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AddEmmaContactModal',
  components: {
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckbox,
  },
  props: {
  },
  data() {
    return {
      name: '',
      phoneNumber: '',
      email: '',
      mainContact: false,
      submitPressed: false,
    }
  },
  computed: {
    contactList() {
      return this.$store.state.emmasettings.contacts
    },
    numberValid() {
      if (!this.submitPressed) {
        return undefined
      }
      return this.isValidPhoneNumber()
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    resetModal() {
      this.name = ''
      this.phoneNumber = ''
      this.email = ''
      this.mainContact = false
    },
    loadData() {
      this.name = ''
      this.phoneNumber = ''
      this.email = ''
      this.mainContact = false
      this.submitPressed = false
    },
    isValidPhoneNumber() {
      const number = this.phoneNumber.trim()
      const regex = /^\+[0-9]{2,}$/
      return regex.test(number)
    },
    async handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()

      this.submitPressed = true
      if (!this.numberValid) {
        return
      }

      const contact = {
        serial: this.$store.state.emmasettings.selectedEmma.serial,
        name: this.name,
        email: this.email,
        telephone: this.phoneNumber,
        maincontact: this.mainContact,
      }

      if (this.contactList.length === 0) {
        contact.maincontact = true
      }

      try {
        await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/contacts`, contact)
        await this.$store.dispatch('emmasettings/getContactList')
        const toastText = `Kontakt ${this.name} erfolgreich erstellt`
        this.$toast({
          component: ToastificationContent,
          props: {
            title: toastText,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        const toastText = `Kontakt ${this.name} konnte nicht erstellt werden`
        this.$toast({
          component: ToastificationContent,
          props: {
            title: toastText,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        console.error(e)
      }
      this.$nextTick(() => {
        this.$bvModal.hide('add-emma-contact-modal')
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
