<template>
  <div>
    <div v-if="!loading && Object.keys(devices).length !== 0">
      <b-row>
        <b-col cols="12">
          <b-card>
            <h6 class="text-primary font-weight-bold mb-2">
              {{ $t('devices') }}
            </h6>
            <!-- draggable -->
            <draggable
              :list="lists.noGroupList"
              tag="ul"
              group="devicegroup"
              class="list-group list-group-flush cursor-move"
              @end="saveSmarthomeMapping()"
            >
              <b-list-group-item
                v-for="(listItem, index) in lists.noGroupList"
                :key="index"
                tag="li"
              >
                <smarthome-manager-item
                  :list-item="listItem"
                  @device-removed="loadSmarthomeMapping()"
                />
              </b-list-group-item>
            </draggable>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="4"
          cols="12"
        >
          <b-card>
            <h6 class="text-primary font-weight-bold mb-2">
              {{ $t('livingroom') }}
            </h6>

            <!-- draggable -->
            <draggable
              :list="lists.livingroomList"
              tag="ul"
              group="devicegroup"
              class="list-group list-group-flush cursor-move"
              @end="saveSmarthomeMapping()"
            >
              <b-list-group-item
                v-for="(listItem, index) in lists.livingroomList"
                :key="index"
                tag="li"
              >
                <smarthome-manager-item
                  :list-item="listItem"
                  @device-removed="loadSmarthomeMapping()"
                />
              </b-list-group-item>
            </draggable>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="4"
          cols="12"
        >
          <b-card>
            <h6 class="text-primary font-weight-bold mb-2">
              {{ $t('bedroom') }}
            </h6>

            <!-- draggable -->
            <draggable
              :list="lists.bedroomList"
              tag="ul"
              group="devicegroup"
              class="list-group list-group-flush cursor-move"
              @end="saveSmarthomeMapping()"
            >
              <b-list-group-item
                v-for="(listItem, index) in lists.bedroomList"
                :key="index"
                tag="li"
              >
                <smarthome-manager-item
                  :list-item="listItem"
                  @device-removed="loadSmarthomeMapping()"
                />
              </b-list-group-item>
            </draggable>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="4"
          cols="12"
        >
          <b-card>
            <h6 class="text-primary font-weight-bold mb-2">
              {{ $t('kitchen') }}
            </h6>

            <!-- draggable -->
            <draggable
              :list="lists.kitchenList"
              tag="ul"
              group="devicegroup"
              class="list-group list-group-flush cursor-move"
              @end="saveSmarthomeMapping()"
            >
              <b-list-group-item
                v-for="(listItem, index) in lists.kitchenList"
                :key="index"
                tag="li"
              >
                <smarthome-manager-item
                  :list-item="listItem"
                  @device-removed="loadSmarthomeMapping()"
                />
              </b-list-group-item>
            </draggable>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          md="6"
          lg="4"
          cols="12"
        >
          <b-card>
            <h6 class="text-primary font-weight-bold mb-2">
              {{ $t('bathroom') }}
            </h6>

            <!-- draggable -->
            <draggable
              :list="lists.bathroomList"
              tag="ul"
              group="devicegroup"
              class="list-group list-group-flush cursor-move"
              @end="saveSmarthomeMapping()"
            >
              <b-list-group-item
                v-for="(listItem, index) in lists.bathroomList"
                :key="index"
                tag="li"
              >
                <smarthome-manager-item
                  :list-item="listItem"
                  @device-removed="loadSmarthomeMapping()"
                />
              </b-list-group-item>
            </draggable>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="4"
          cols="12"
        >
          <b-card>
            <h6 class="text-primary font-weight-bold mb-2">
              {{ $t('house') }}
            </h6>

            <!-- draggable -->
            <draggable
              :list="lists.houseList"
              tag="ul"
              group="devicegroup"
              class="list-group list-group-flush cursor-move"
              @end="saveSmarthomeMapping()"
            >
              <b-list-group-item
                v-for="(listItem, index) in lists.houseList"
                :key="index"
                tag="li"
              >
                <smarthome-manager-item
                  :list-item="listItem"
                  @device-removed="loadSmarthomeMapping()"
                />
              </b-list-group-item>
            </draggable>
          </b-card>
        </b-col>
        <b-col
          md="6"
          lg="4"
          cols="12"
        >
          <b-card>
            <h6 class="text-primary font-weight-bold mb-2">
              {{ $t('office') }}
            </h6>

            <!-- draggable -->
            <draggable
              :list="lists.officeList"
              tag="ul"
              group="devicegroup"
              class="list-group list-group-flush cursor-move"
              @end="saveSmarthomeMapping()"
            >
              <b-list-group-item
                v-for="(listItem, index) in lists.officeList"
                :key="index"
                tag="li"
              >
                <smarthome-manager-item
                  :list-item="listItem"
                  @device-removed="loadSmarthomeMapping()"
                />
              </b-list-group-item>
            </draggable>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="!loading && Object.keys(devices).length === 0">
      <b-card>
        <b-card-header>
          <b-card-title>
            {{ $t('noSmarthomeDevicesPaired') }}
          </b-card-title>
        </b-card-header>
        <b-card-body>
          {{ $t('pleaseConnectDevicesWithYourEmmaFirst') }}
        </b-card-body>
      </b-card>
    </div>
    <b-card v-if="loading">
      <b-skeleton
        animation="wave"
        width="85%"
      />
      <b-skeleton
        animation="wave"
        width="55%"
      />
      <b-skeleton
        animation="wave"
        width="70%"
      />
      <b-skeleton
        animation="wave"
        width="85%"
      />
      <b-skeleton
        animation="wave"
        width="55%"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BListGroupItem, BCard, BSkeleton, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SmarthomeManagerItem from '@/components/emma/smarthome/SmarthomeManagerItem.vue'

export default {
  name: 'SmarthomeManager',
  components: {
    BRow,
    BCol,
    BListGroupItem,
    draggable,
    BCard,
    BSkeleton,
    SmarthomeManagerItem,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  data() {
    return {
      loading: false,
      adlaiId: '',
      lists: {
        noGroupList: [],
        livingroomList: [],
        bedroomList: [],
        kitchenList: [],
        bathroomList: [],
        houseList: [],
        officeList: [],
      },
    }
  },
  computed: {
    devices() {
      return this.$store.state.smarthome.mapping.devices
    },
    groups() {
      return this.$store.state.smarthome.mapping.groups
    },
    selectedEmma() {
      return this.$store.state.emmasettings.selectedEmma
    },
    fullMapping() {
      const smarthomeMapping = {
        devices: this.devices,
        groups: {},
      }
      Object.keys(smarthomeMapping.devices).forEach(device => {
        if (Object.hasOwn(smarthomeMapping.devices[device], 'group')) {
          delete smarthomeMapping.devices[device].group
        }
      })

      if (this.lists.livingroomList.length !== 0) {
        smarthomeMapping.groups['livingroom/general/0'] = []
      }
      for (let i = 0; i < this.lists.livingroomList.length; i += 1) {
        const identifier = (this.lists.livingroomList[i].uniqueid) ? this.lists.livingroomList[i].uniqueid : this.lists.livingroomList[i].id
        smarthomeMapping.groups['livingroom/general/0'].push(
          identifier,
        )
        smarthomeMapping.devices[identifier].group = 'livingroom/general/0'
      }

      if (this.lists.bedroomList.length !== 0) {
        smarthomeMapping.groups['bedroom/general/0'] = []
      }
      for (let i = 0; i < this.lists.bedroomList.length; i += 1) {
        const identifier = (this.lists.bedroomList[i].uniqueid) ? this.lists.bedroomList[i].uniqueid : this.lists.bedroomList[i].id
        smarthomeMapping.groups['bedroom/general/0'].push(
          identifier,
        )
        smarthomeMapping.devices[identifier].group = 'bedroom/general/0'
      }
      if (this.lists.kitchenList.length !== 0) {
        smarthomeMapping.groups['kitchen/general/0'] = []
      }
      for (let i = 0; i < this.lists.kitchenList.length; i += 1) {
        const identifier = (this.lists.kitchenList[i].uniqueid) ? this.lists.kitchenList[i].uniqueid : this.lists.kitchenList[i].id
        smarthomeMapping.groups['kitchen/general/0'].push(
          identifier,
        )
        smarthomeMapping.devices[identifier].group = 'kitchen/general/0'
      }
      if (this.lists.bathroomList.length !== 0) {
        smarthomeMapping.groups['bathroom/general/0'] = []
      }
      for (let i = 0; i < this.lists.bathroomList.length; i += 1) {
        const identifier = (this.lists.bathroomList[i].uniqueid) ? this.lists.bathroomList[i].uniqueid : this.lists.bathroomList[i].id
        smarthomeMapping.groups['bathroom/general/0'].push(
          identifier,
        )
        smarthomeMapping.devices[identifier].group = 'bathroom/general/0'
      }
      if (this.lists.houseList.length !== 0) {
        smarthomeMapping.groups['house/general/0'] = []
      }
      for (let i = 0; i < this.lists.houseList.length; i += 1) {
        const identifier = (this.lists.houseList[i].uniqueid) ? this.lists.houseList[i].uniqueid : this.lists.houseList[i].id
        smarthomeMapping.groups['house/general/0'].push(
          identifier,
        )
        smarthomeMapping.devices[identifier].group = 'house/general/0'
      }
      if (this.lists.officeList.length !== 0) {
        smarthomeMapping.groups['office/general/0'] = []
      }
      for (let i = 0; i < this.lists.officeList.length; i += 1) {
        const identifier = (this.lists.officeList[i].uniqueid) ? this.lists.officeList[i].uniqueid : this.lists.officeList[i].id
        smarthomeMapping.groups['office/general/0'].push(
          identifier,
        )
        smarthomeMapping.devices[identifier].group = 'office/general/0'
      }

      return smarthomeMapping
    },
  },
  watch: {
  },
  async mounted() {
    await this.loadSmarthomeMapping()
  },
  methods: {
    resetLists() {
      this.lists = {
        noGroupList: [],
        livingroomList: [],
        bedroomList: [],
        kitchenList: [],
        bathroomList: [],
        houseList: [],
        officeList: [],
      }
    },
    async saveSmarthomeMapping() {
      try {
        await this.$store.commit('smarthome/setMapping', this.fullMapping)
        await this.$store.dispatch('smarthome/sendSmarthomeMapping', this.selectedEmma)
        this.makeToast('Konfiguration gespeichert!', 'CheckSquareIcon', 'success', '')
      } catch (e) {
        this.makeToast('Konfiguration konnte nicht gespeichert werden!', 'XIcon', 'danger', '')
      }
    },
    async loadSmarthomeMapping() {
      this.loading = true
      this.resetLists()
      try {
        await this.$store.dispatch('smarthome/getSmarthomeMapping', { serial: this.selectedEmma.serial })
        Object.keys(this.groups).forEach(group => {
          if (group.startsWith('livingroom')) {
            for (let i = 0; i < this.groups[group].length; i += 1) {
              if (this.devices[this.groups[group][i]]) {
                this.lists.livingroomList.push(this.devices[this.groups[group][i]])
              }
            }
          } else if (group.startsWith('bedroom')) {
            for (let i = 0; i < this.groups[group].length; i += 1) {
              if (this.devices[this.groups[group][i]]) {
                this.lists.bedroomList.push(this.devices[this.groups[group][i]])
              }
            }
          } else if (group.startsWith('bathroom')) {
            for (let i = 0; i < this.groups[group].length; i += 1) {
              if (this.devices[this.groups[group][i]]) {
                this.lists.bathroomList.push(this.devices[this.groups[group][i]])
              }
            }
          } else if (group.startsWith('kitchen')) {
            for (let i = 0; i < this.groups[group].length; i += 1) {
              if (this.devices[this.groups[group][i]]) {
                this.lists.kitchenList.push(this.devices[this.groups[group][i]])
              }
            }
          } else if (group.startsWith('house')) {
            for (let i = 0; i < this.groups[group].length; i += 1) {
              if (this.devices[this.groups[group][i]]) {
                this.lists.houseList.push(this.devices[this.groups[group][i]])
              }
            }
          } else if (group.startsWith('office')) {
            for (let i = 0; i < this.groups[group].length; i += 1) {
              if (this.devices[this.groups[group][i]]) {
                this.lists.officeList.push(this.devices[this.groups[group][i]])
              }
            }
          }
        })
        Object.keys(this.devices).forEach(device => {
          if (!Object.hasOwn(this.devices[device], 'group')) {
            if (this.devices[device]) {
              this.lists.noGroupList.push(this.devices[device])
            }
          }
        })
        this.loading = false
      } catch (e) {
        await this.$store.commit('smarthome/resetMapping')
        this.loading = false
      }
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
