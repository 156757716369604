<template>
  <div><b-card
         v-if="!loadingSensors"
       >
         <b-card-header>
           <h2>
             {{ $t('emmaSensorLog') }}
           </h2>
         </b-card-header>
         <b-row class="mb-1">
           <b-col
             cols="12"
             lg="9"
           >
             <b-form-select
               v-model="selected"
               :options="options"
             />
           </b-col>
           <b-col
             lg="1"
             cols="4"
           >
             <b-button
               block
               :disabled="pageNumber === 1"
               @click="pageNumber -= 1"
             >
               <feather-icon
                 icon="ArrowLeftIcon"
               />
             </b-button>
           </b-col>
           <b-col
             lg="1"
             cols="4"
           >
             <b-button
               variant="primary"
               block
               @click="updateLog"
             >
               <feather-icon
                 icon="RefreshCwIcon"
               />
             </b-button>
           </b-col>
           <b-col
             lg="1"
             cols="4"
           >
             <b-button
               block
               :disabled="pageNumber === amountOfPages"
               @click="pageNumber += 1"
             >
               <feather-icon
                 icon="ArrowRightIcon"
               />
             </b-button>
           </b-col>
         </b-row>
         <b-row>
           <b-col>
             <b-table
               striped
               hover
               :busy="loadingLog"
               :items="paginatedItems"
               :fields="fields"
             >
               <template #table-busy>
                 <div class="text-center text-primary my-2">
                   <b-spinner class="align-middle mr-1" />
                   <strong>{{ $t('dataLoading') }}</strong>
                 </div>
               </template>
             </b-table>
           </b-col>
         </b-row>
         <b-row>
           <b-col>
             <b-button
               block
               variant="danger"
               class="mt-1"
               @click="clearSensorData()"
             >
               {{ $t('deleteSensorData') }}
             </b-button>
           </b-col>
         </b-row>
       </b-card>
    <b-card v-else>
      <b-card-header>
        <h2>
          {{ $t('emmaSensorLog') }}
        </h2>
      </b-card-header>
      <b-skeleton
        animation="wave"
        width="85%"
      />
      <b-skeleton
        animation="wave"
        width="55%"
      />
      <b-skeleton
        animation="wave"
        width="70%"
      />
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardHeader, BFormSelect, BTable, BButton, BRow, BCol, BSkeleton, BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'EmmaDebugLog',
  components: {
    BCard, BCardHeader, BSkeleton, BFormSelect, BTable, BButton, BRow, BCol, BSpinner,
  },
  props: {
    selectedEmma: {
      type: Object,
      required: true,
    },
    debug: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      pageSize: 15,
      pageNumber: 1,
      fields: [
        {
          key: 'id',
          sortable: true,
        },
        {
          key: 'timestamp',
          label: 'Uhrzeit',
          sortable: true,
        },
        {
          key: 'value',
          label: 'Wert',
          sortable: true,
        },
      ],
      items: [{
        date: new Date(),
        text1: 'text1',
        text2: 'text2',
      }],
      options: [],
      sensorOptions: [],
      selected: '',
      loadingLog: false,
      loadingSensors: false,
    }
  },
  computed: {
    amountOfPages() {
      return Math.ceil(this.items.length / this.pageSize)
    },
    paginatedItems() {
      return this.items.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize)
    },
  },
  watch: {
    selected() {
      this.updateLog()
    },
  },
  async mounted() {
    await this.getSensors()
    await this.updateLog()
  },
  methods: {
    async clearSensorData() {
      try {
        await this.$http.delete(`/emmas/${this.selectedEmma.serial}/statistics`)
        await this.updateLog()
        await this.getSensors()
      } catch (e) {
        console.error(e)
      }
    },
    async getSensors() {
      this.loadingSensors = true
      const response = await this.$http.get(`/emmas/${this.selectedEmma.serial}/statistics`)
      const smarthomeSensors = []
      // this.sensorOptions = response.data
      if (!this.debug) {
        for (let i = 0; i < response.data.length; i += 1) {
          if (!response.data[i].startsWith('emma')) {
            if (response.data[i] === 'smarthome/emma/movement') {
              smarthomeSensors.push({ text: 'Emma Bewegungssensor', value: response.data[i] })
            } else {
              smarthomeSensors.push({ text: response.data[i].replace('smarthome/', ''), value: response.data[i] })
            }
          }
        }
        this.options = smarthomeSensors
        if (this.selected === '' && this.options.length !== 0) {
          this.selected = this.options[0].value
        }
      } else {
        this.options = response.data
        if (this.selected === '' && this.options.length !== 0) {
          [this.selected] = this.options
        }
      }
      this.loadingSensors = false
    },
    async updateLog() {
      if (this.sensorOptions.length === 0) {
        await this.getSensors()
      }
      if (!this.selected) {
        return
      }
      try {
        this.loadingLog = true
        const result = await this.$http.get(`/emmas/${this.selectedEmma.serial}/statistics/sensor/?name=${this.selected}&limit=1000`)
        this.items = result.data
      } catch (err) {
        console.error(err)
      }
      this.loadingLog = false
    },
  },
}
</script>

<style scoped>

</style>
