<template>
  <div class="d-flex">
    <b-avatar
      v-if="listItem.connection === 'hue'"
      variant="primary"
      text="HUE"
    />
    <b-avatar
      v-if="listItem.connection === 'zigbee'"
      variant="dark"
      text="ZIG"
    />
    <b-avatar
      v-if="listItem.connection === 'loxone'"
      variant="success"
      text="LOX"
    />
    <div class="ml-25">
      <b-card-text
        v-if="listItem.friendlyName"
        class="mb-0 font-weight-bold"
      >
        {{ listItem.friendlyName }}
      </b-card-text>
      <b-card-text
        v-else-if="listItem.name"
        class="mb-0 font-weight-bold"
      >
        {{ listItem.name }}
      </b-card-text>
      <b-card-text
        v-else
        class="mb-0 font-weight-bold"
      >
        {{ listItem.id }}
      </b-card-text>
      <small v-if="listItem.connection !== 'hue'">{{ listItem.id }}</small>
      <small v-else>{{ listItem.uniqueid }}</small>
    </div>
    <b-button
      variant="danger"
      class="ml-auto"
      @click="removeDevice(listItem)"
    >
      <feather-icon
        icon="TrashIcon"
        size="16"
      />
    </b-button>
  </div>
</template>

<script>
import {
  BCardText, BAvatar, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'SmarthomeManagerItem',
  components: {
    BCardText, BAvatar, BButton,
  },
  props: {
    listItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectedEmma() {
      return this.$store.state.emmasettings.selectedEmma
    },
  },
  methods: {
    async removeDevice(device) {
      const id = (device.connection === 'hue') ? device.uniqueid : device.id
      try {
        await this.$store.dispatch('smarthome/removeDevice', device)
        await this.$store.dispatch('smarthome/sendSmarthomeMapping', this.selectedEmma)
        this.makeToast('Erfolgreich!', 'CheckSquareIcon', 'success', `Gerät ${id} wurde gelöscht!`)
        this.$emit('device-removed')
      } catch (e) {
        console.error(e)
        this.makeToast('Fehler!', 'XIcon', 'danger', `Gerät ${id} konnte nicht gelöscht werden!`)
      }
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
