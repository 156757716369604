<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-card-header>
            <div>
              <h2>
                {{ $t("wifiSettings") }}
              </h2>
            </div>
          </b-card-header>
          <b-row>
            <b-col md="5" cols="12" class="mb-1">
              <b-form-input v-model="ssid" :placeholder="$t('ssid')" />
            </b-col>
            <b-col md="5" cols="12" class="mb-1">
              <b-form-input v-model="password" :state="passwordHighlight" :placeholder="$t('password')" />
            </b-col>
            <b-col md="2" cols="12">
              <b-button block :disabled="ssid === ''" variant="primary" @click="sendWifiSettingsToEmma">
                {{ $t("save") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" cols="12">
        <b-card-actions
          ref="availableNetworkCard"
          title="Netzwerke in der Umgebung"
          action-refresh
          @refresh="getNearbyWifi()"
        >
          <b-card-text>
            <div
              v-for="(network, index) in availableNetworks"
              v-if="activeSSID !== network"
              :key="'availablenetwork' + index"
              style="
                padding: 3px;
                margin: 2px;
                border: 3px solid;
                border-radius: 10px;
                border-color: rgba(151, 192, 214, 0.5);
              "
            >
              <b-row>
                <b-col sm="9" cols="8" class="d-flex align-items-center justify-content-center">
                  {{ network }}
                </b-col>
                <b-col sm="3" cols="4">
                  <b-button size="sm" variant="primary" style="width: 100%" @click="ssid = network"> Save </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card-text>
        </b-card-actions>
      </b-col>
      <b-col lg="6" cols="12">
        <b-card-actions ref="savedNetworkCard" title="Gespeicherte netzwerke" action-refresh @refresh="getSavedWifi()">
          <b-card-text>
            <div
              v-for="(network, index) in savedNetworks"
              :key="'savednetwork' + index"
              :style="
                activeSSID === network
                  ? 'padding: 3px; margin: 2px; border: 3px solid; border-radius: 10px; border-color: rgba(40,199,111,0.5)'
                  : 'padding: 3px; margin: 2px; border: 3px solid; border-radius: 10px; border-color: rgba(151,192,214,0.50)'
              "
            >
              <b-row>
                <b-col sm="6" cols="4" class="d-flex align-items-center justify-content-center">
                  {{ network }}
                </b-col>
                <b-col sm="3" cols="4">
                  <b-button
                    :disabled="activeSSID === network"
                    size="sm"
                    variant="primary"
                    style="width: 100%"
                    @click="ssid = network"
                  >
                    Edit
                  </b-button>
                </b-col>
                <b-col sm="3" cols="4">
                  <b-button
                    :disabled="activeSSID === network"
                    size="sm"
                    variant="danger"
                    style="width: 100%"
                    @click="deleteWifiBySSID(network)"
                  >
                    Delete
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-card-text>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"

import { BCard, BCardText, BCardHeader, BButton, BFormInput, BRow, BCol } from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  name: "EmmaWifiConfigurator",
  components: {
    BCard,
    BCardHeader,
    BButton,
    BFormInput,
    BRow,
    BCol,
    BCardActions,
    BCardText,
  },
  data() {
    return {
      ssid: "",
      password: "",
      passwordChecked: undefined,
      activeSSID: undefined,
      savedNetworks: [],
      availableNetworks: [],
    }
  },
  computed: {
    wifiSettings() {
      return { ssid: this.ssid, password: this.password }
    },
    passwordHighlight() {
      if (this.passwordChecked === undefined) {
        return undefined
      }
      return !(this.passwordChecked && this.password.length < 8)
    },
  },
  async mounted() {
    await this.getActiveWifi()
    await this.getSavedWifi()
    await this.getNearbyWifi()
  },
  methods: {
    async sendWifiSettingsToEmma() {
      this.passwordChecked = true
      if (this.password.length < 8) {
        this.makeToast("Fehler!", "XIcon", "danger", "Passwort muss mindestens 8 Zeichen lang sein!")
        return
      }
      try {
        await this.$store.dispatch("emmas/saveWifiToEmma", {
          serial: this.$store.state.emmasettings.selectedEmma.serial,
          wifi: this.wifiSettings,
        })
        await this.getSavedWifi()
        this.makeToast("Erfolgreich!", "CheckSquareIcon", "success", "Wifi-Einstellungen wurden gespeichert!")
      } catch (e) {
        console.error(e)
        this.makeToast("Fehler!", "XIcon", "danger", "Wifi-Einstellungen konnten nicht gespeichert werden!")
      }
    },
    async deleteWifiBySSID(ssid) {
      try {
        await this.$store.dispatch("emmas/deleteWifiOffEmma", {
          serial: this.$store.state.emmasettings.selectedEmma.serial,
          wifi: { ssid },
        })

        await this.getSavedWifi()
        this.makeToast("Erfolgreich!", "CheckSquareIcon", "success", `Wifi: ${ssid} erfolgreich gelöscht!`)
      } catch (e) {
        console.error(e)
        this.makeToast("Fehler!", "XIcon", "danger", `Wifi: ${ssid} konnte nicht gelöscht werden!`)
      }
    },
    async getNearbyWifi() {
      this.$refs.availableNetworkCard.showLoading = true
      try {
        const response = await this.$http.get(
          `/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/config/wifi/nearby/list`
        )
        this.availableNetworks = response.data
      } catch (e) {
        console.error(e)
      }
      if (this.$refs?.availableNetworkCard) { // avoid exception if the user switches tabs while loading is still in progress
        this.$refs.availableNetworkCard.showLoading = false
      }
    },
    async getSavedWifi() {
      this.$refs.savedNetworkCard.showLoading = true
      try {
        const response = await this.$http.get(
          `/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/config/wifi/saved/list`
        )
        this.savedNetworks = response.data
      } catch (e) {
        console.error(e)
      }
      this.$refs.savedNetworkCard.showLoading = false
    },
    async getActiveWifi() {
      try {
        const response = await this.$http.get(
          `/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/config/wifi/ssid`
        )
        this.activeSSID = response.data.wifiSsid
      } catch (e) {
        console.error(e)
      }
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped></style>
