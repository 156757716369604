<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>
          {{ $t('drinkreminder') }}
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            md="4"
            cols="12"
            class="mb-1"
          >
            <b-button
              :variant="(settingsReminder.times.morning) ? 'primary' : 'outline-dark'"
              :disabled="loading"
              class="square-button"
              style="width: 100%"
              @click="toggleMorningEnabled"
            >
              <feather-icon
                v-if="!morningLoading"
                icon="TrendingUpIcon"
                size="50"
                class="align-text-top mb-1"
              />
              <b-spinner
                v-if="morningLoading"
                class="p-2 mb-1"
              />
              <br>
              {{ $t('morning') }}
            </b-button>
          </b-col>
          <b-col
            md="4"
            cols="12"
            class="mb-1"
          >
            <b-button
              :variant="(settingsReminder.times.noon) ? 'primary' : 'outline-dark'"
              :disabled="loading"
              class="square-button"
              style="width: 100%"
              @click="toggleNoonEnabled"
            >
              <feather-icon
                v-if="!noonLoading"
                icon="SunIcon"
                size="50"
                class="align-text-top mb-1"
              />
              <b-spinner
                v-if="noonLoading"
                class="p-2 mb-1"
              />
              <br>
              {{ $t('noon') }}
            </b-button>
          </b-col>
          <b-col
            md="4"
            cols="12"
            class="mb-1"
          >
            <b-button
              :variant="(settingsReminder.times.evening) ? 'primary' : 'outline-dark'"
              :disabled="loading"
              class="square-button"
              style="width: 100%"
              @click="toggleEveningEnabled"
            >
              <feather-icon
                v-if="!eveningLoading"
                icon="TrendingDownIcon"
                size="50"
                class="align-text-top mb-1"
              />
              <b-spinner
                v-if="eveningLoading"
                class="p-2 mb-1"
              />
              <br>
              {{ $t('evening') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col>
            <b-button
              v-b-modal.drink-modal-1
              variant="flat-primary"
            >
              {{ $t('editTimes') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- basic modal -->
    <b-modal
      id="drink-modal-1"
      :title="$t('changeTimes')"
      centered
      ok-only
      :ok-title="$t('save')"
      @ok="handleOk"
    >
      <b-card-text>
        <h3>{{ $t('morning') }}</h3>
        <b-form-timepicker
          v-model="morningTime"
          locale="de"
        />
        <h3 class="mt-2">
          {{ $t('noon') }}
        </h3>
        <b-form-timepicker
          v-model="noonTime"
          locale="de"
        />
        <h3 class="mt-2">
          {{ $t('evening') }}
        </h3>
        <b-form-timepicker
          v-model="eveningTime"
          locale="de"
        />
      </b-card-text>
      <template #modal-footer="{ ok }">
        <b-button
          block
          variant="primary"
          @click="ok()"
        >
          {{ $t('ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardBody, BRow, BCol, BFormTimepicker, BCardText, BCardHeader, BCardTitle, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DrinkReminderConfigurator',
  components: {
    BButton,
    BCardText,
    BRow,
    BCol,
    BCard,
    BFormTimepicker,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BSpinner,
  },
  data() {
    return {
      morningEnabled: false,
      noonEnabled: false,
      eveningEnabled: false,
      morningLoading: false,
      noonLoading: false,
      eveningLoading: false,
      morningTime: undefined,
      noonTime: undefined,
      eveningTime: undefined,
    }
  },
  computed: {
    timeDefintions() {
      return { morning: this.morningTime || '09:00:00', noon: this.noonTime || '12:00:00', evening: this.eveningTime || '17:00:00' }
    },
    times() {
      return { morning: this.morningEnabled, noon: this.noonEnabled, evening: this.eveningEnabled }
    },
    fullReminder() {
      return { times: this.times, timeDefinitions: this.timeDefintions }
    },
    settingsReminder() {
      return this.$store.state.emmasettings.settings.reminder.drinking
    },
    loading() {
      return (this.morningLoading || this.noonLoading || this.eveningLoading)
    },
  },
  async mounted() {
    try {
      this.loadReminderSettings()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Trinkerinnerungen konnten nicht geladen werden',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    }
  },
  methods: {
    loadReminderSettings() {
      if (this.$store.state.emmasettings.settings.reminder.drinking.times.morning) {
        this.morningEnabled = this.$store.state.emmasettings.settings.reminder.drinking.times.morning
      } else {
        this.morningEnabled = false
      }

      if (this.$store.state.emmasettings.settings.reminder.drinking.times.noon) {
        this.noonEnabled = this.$store.state.emmasettings.settings.reminder.drinking.times.noon
      } else {
        this.noonEnabled = false
      }

      if (this.$store.state.emmasettings.settings.reminder.drinking.times.evening) {
        this.eveningEnabled = this.$store.state.emmasettings.settings.reminder.drinking.times.evening
      } else {
        this.eveningEnabled = false
      }
      this.morningTime = this.$store.state.emmasettings.settings.reminder.drinking.timeDefinitions.morning || '09:00:00'
      this.noonTime = this.$store.state.emmasettings.settings.reminder.drinking.timeDefinitions.noon || '12:00:00'
      this.eveningTime = this.$store.state.emmasettings.settings.reminder.drinking.timeDefinitions.evening || '17:00:00'
    },
    async toggleMorningEnabled() {
      this.morningLoading = true
      this.morningEnabled = !this.settingsReminder.times.morning
      await this.saveToEmma()
      this.morningEnabled = this.settingsReminder.times.morning
      this.morningLoading = false
    },
    async toggleNoonEnabled() {
      this.noonLoading = true
      this.noonEnabled = !this.settingsReminder.times.noon
      await this.saveToEmma()
      this.morningEnabled = this.settingsReminder.times.morning
      this.noonLoading = false
    },
    async toggleEveningEnabled() {
      this.eveningLoading = true
      this.eveningEnabled = !this.settingsReminder.times.evening
      await this.saveToEmma()
      this.morningEnabled = this.settingsReminder.times.morning
      this.eveningLoading = false
    },
    async handleOk() {
      await this.saveToEmma()
    },
    async saveToEmma() {
      try {
        await this.$store.dispatch('emmasettings/saveDrinkReminders', this.fullReminder)
        await this.$store.commit('emmasettings/setDrinkReminder', this.fullReminder)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Trinkerinnerung gespeichert',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Trinkerinnerung konnte nicht gespeichert werden',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style scoped>

.square-button {
  width: 5vw;
  height: 5vw;
  min-width: 100px;
  min-height: 100px;
  /*opacity: 0.50*/
}

</style>
