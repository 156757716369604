<template>
  <b-card>
    <b-card-header>
      <h2>
        {{ $t('emmaDebugLog') }}
      </h2>
    </b-card-header>
    <b-row class="mb-1">
      <b-col
        cols="12"
        lg="9"
      >
        <b-form-select
          v-model="selected"
          :options="options"
        />
      </b-col>
      <b-col
        lg="1"
        cols="4"
      >
        <b-button
          block
          :disabled="pageNumber === 1"
          @click="pageNumber -= 1"
        >
          <feather-icon
            icon="ArrowLeftIcon"
          />
        </b-button>
      </b-col>
      <b-col
        lg="1"
        cols="4"
      >
        <b-button
          variant="primary"
          block
          @click="updateLog"
        >
          <feather-icon
            icon="RefreshCwIcon"
          />
        </b-button>
      </b-col>
      <b-col
        lg="1"
        cols="4"
      >
        <b-button
          block
          :disabled="pageNumber === amountOfPages"
          @click="pageNumber += 1"
        >
          <feather-icon
            icon="ArrowRightIcon"
          />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          v-if="!loading"
          striped
          hover
          :items="paginatedItems"
          :fields="fields"
        />
        <b-spinner v-else />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          block
          variant="danger"
          class="mt-1"
          @click="clearLogData()"
        >
          {{ $t('deleteDebugData') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BSpinner, BCardHeader, BFormSelect, BTable, BButton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'EmmaDebugLog',
  components: {
    BCard, BSpinner, BCardHeader, BFormSelect, BTable, BButton, BRow, BCol,
  },
  data() {
    return {
      pageSize: 15,
      pageNumber: 1,
      fields: [
        {
          key: 'id',
          sortable: true,
        },
        {
          key: 'timestamp',
          sortable: true,
        },
        {
          key: 'text1',
          sortable: true,
        },
        {
          key: 'text2',
          sortable: true,
        },
        {
          key: 'text3',
          sortable: true,
        },
      ],
      items: [],
      options: [
        { value: 'tts|stt', text: 'tts|stt' },
        { value: 'mqtt', text: 'mqtt' },
        { value: 'smarthome', text: 'smarthome' },
        { value: 'hardware', text: 'hardware' },
        { value: 'power', text: 'power' },
        { value: 'bluetooth', text: 'bluetooth' },
        { value: 'wifi', text: 'wifi' },
      ],
      selected: 'tts|stt',
      loading: false,
    }
  },
  computed: {
    amountOfPages() {
      return Math.ceil(this.items.length / this.pageSize)
    },
    paginatedItems() {
      return this.items.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize)
    },
  },
  watch: {
    selected() {
      this.updateLog()
    },
  },
  async mounted() {
    await this.updateLog()
  },
  methods: {
    async updateLog() {
      if (!this.selected) {
        return
      }
      try {
        this.loading = true
        const result = await this.$http.get(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/dev/log/${this.selected}?limit=1000`)
        this.items = result.data
        if (this.selected === 'smarthome' || this.selected === 'hardware' || this.selected === 'power') {
          for (let i = 0; i < this.items.length; i += 1) {
            this.items[i].text2 = JSON.stringify(this.items[i].text2)
            this.items[i].text2 = this.items[i].text2.replaceAll('\\', '')
            this.items[i].text2 = this.items[i].text2.replaceAll(',', ', ')
          }
        }
      } catch (err) {
        this.items = []
        console.error(err)
      }
      this.loading = false
    },
    async clearLogData() {
      try {
        await this.$http.delete(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/dev/log`)
        await this.updateLog()
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style scoped>

</style>
