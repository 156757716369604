<template>
  <div>
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      :busy="loading"
      show-empty
      class="mb-0 card-company-table"
    >
      <template #cell(maincontact)="data">
        <div class="d-flex align-items-center">

          <b-avatar
            class="mr-1"
            :variant="(data.item.maincontact) ? 'success' : 'danger'"
          >
            <feather-icon
              size="18"
              :icon="(data.item.maincontact) ? 'PhoneCallIcon' : 'PhoneMissedIcon'"
            />
          </b-avatar>
          <span>{{ (data.item.maincontact) ? $t('mainContact') : $t('secondaryContact') + " " + data.index }}</span>
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>{{ $t('loadingContacts') }}</strong>
        </div>
      </template>

      <template #cell(call)="data">
        <div class="d-flex align-items-center">
          <b-button
            variant="outline-success"
            @click="startCallOnEmma(data)"
          >
            {{ $t('startCall') }}
          </b-button>
        </div>
      </template>
      <template #cell(edit)="data">
        <div class="d-flex align-items-center">

          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-b-modal.edit-emma-contact-modal
                @click="selectedUser = data.item"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>{{ $t('edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="deleteUser(data)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span>{{ $t('delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </div>
      </template>
      <template #empty>
        <div
          class="text-center mt-1"
        >
          <b-alert
            :show="true"
            variant="danger"
            class="mb-0"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              {{ $t('noContactsFoundOnEmma') }}
            </div>
          </b-alert>
        </div>
      </template>
    </b-table>
    <edit-emma-contact-modal
      :selected-user="selectedUser"
    />
  </div>
</template>

<script>
import {
  BDropdown, BTable, BAvatar, BButton, BDropdownItem, BSpinner, BAlert,
} from 'bootstrap-vue'
import EditEmmaContactModal from '@/components/forms/EditEmmaContactModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BAvatar,
    BButton,
    BDropdown,
    BDropdownItem,
    EditEmmaContactModal,
    BSpinner,
    BAlert,
  },
  props: {
  },
  data() {
    return {
      selectedUser: {},
      contactList: [],
      loading: false,
      fields: [
        { key: 'name', label: this.$t('name') },
        { key: 'email', label: this.$t('email') },
        { key: 'telephone', label: this.$t('phoneNumber') },
        { key: 'maincontact', label: this.$t('contactType') },
        { key: 'call', label: this.$t('startCall') },
        { key: 'edit', label: this.$t('edit') },
      ],
    }
  },
  computed: {
    tableData() {
      return this.$store.state.emmasettings.contacts
    },
  },
  async mounted() {
    await this.getContactList()
  },
  methods: {
    async getContactList() {
      try {
        this.loading = true
        await this.$store.dispatch('emmasettings/getContactList')
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    async startCallOnEmma(payload) {
      if (payload.item.maincontact) {
        await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/communications/callMainContact/`)
      } else {
        await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/communications/startPrivateCall/`, { contactIndex: payload.index })
      }
    },
    async deleteUser(payload) {
      const contact = payload.item
      try {
        await this.$http.delete(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/contacts/${contact.id}`, contact)
        await this.getContactList()
        const toastText = `Kontakt ${contact.name} erfolgreich gelöscht`
        this.$toast({
          component: ToastificationContent,
          props: {
            title: toastText,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        console.error(e)
        const toastText = `Kontakt ${contact.name} konnte nicht gelöscht werden`
        this.$toast({
          component: ToastificationContent,
          props: {
            title: toastText,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
