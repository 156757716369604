<template>
  <div>
    <b-button-group
      style="width: 100%"
      class="mb-1"
    >
      <b-button
        id="record"
        variant="primary"
        :disabled="recordButtonDisabled"
        @click="recordButtonClicked"
      >
        <feather-icon
          style="height: 1.5vh; width: 100%"
          icon="MicIcon"
        />
      </b-button>
      <b-button
        id="stop"
        variant="primary"
        :disabled="stopButtonDisabled"
        @click="stopButtonClicked"
      >
        <feather-icon
          style="height: 1.5vh; width: 100%"
          icon="SquareIcon"
        />
      </b-button>
      <b-button
        id="play"
        variant="primary"
        :disabled="playButtonDisabled"
        @click="playButtonClicked"
      >
        <feather-icon
          style="height: 1.5vh; width: 100%"
          icon="PlayIcon"
        />
      </b-button>
      <b-button
        id="play"
        variant="primary"
        :disabled="playButtonDisabled"
        @click="deleteButtonClicked"
      >
        <b-row>
          <b-col>
            <feather-icon
              style="height: 1.5vh; width: 100%"
              icon="Trash2Icon"
            />
          </b-col>
        </b-row>

      </b-button>
    </b-button-group>
    <div v-if="recording">
      <span>{{ $t('secondsRemaning') }} <strong> {{ 15 - Math.floor(loadingBarValue/20) }}</strong></span>
      <b-progress
        v-model="loadingBarValue"
        class="mt-1"
        style="height: 25px"
        max="300"
      />
    </div>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BRow, BCol, BProgress,
} from 'bootstrap-vue'

export default {
  name: 'CalendarAudioHandler',
  components: {
    BButton, BButtonGroup, BRow, BCol, BProgress,
  },
  props: {
    calenderEventAudioString: {
      type: String,
      default() {
        return ''
      },
    },
    isEventHandlerSidebarActive: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      recording: false,
      counterInterval: undefined,
      loadingBarValue: 0,
      recordButtonDisabled: false,
      stopButtonDisabled: true,
      playButtonDisabled: true,
      recorder: undefined,
      audio: undefined,
      audioBlob: undefined,
    }
  },

  watch: {
    async isEventHandlerSidebarActive() {
      if (this.recorder) {
        try {
          this.recorder.stop()
        } catch (e) {
          console.log(e)
        }
      }
      this.recording = false
      clearInterval(this.counterInterval)
      if (this.calenderEventAudioString === '') {
        this.recordButtonDisabled = false
        this.stopButtonDisabled = true
        this.playButtonDisabled = true
        this.recorder = undefined
        this.audio = undefined
        this.audioBlob = undefined
      } else {
        this.audioBlob = this.convertStringToBlob()
        this.recordButtonDisabled = false
        this.stopButtonDisabled = true
        this.playButtonDisabled = false
      }
    },
  },
  methods: {
    convertStringToBlob() {
      const dataURI = this.calenderEventAudioString
      const BASE64_MARKER = ';base64,'
      const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
      const base64 = dataURI.substring(base64Index)
      const raw = window.atob(base64)
      const rawLength = raw.length
      const arr = new Uint8Array(new ArrayBuffer(rawLength))

      for (let i = 0; i < rawLength; i += 1) {
        arr[i] = raw.charCodeAt(i)
      }

      return new Blob([arr], {
        type: 'audio/webm;codecs=opus',
      })
    },
    async recordButtonClicked() {
      this.recording = true
      this.recordButtonDisabled = true
      this.stopButtonDisabled = false
      this.playButtonDisabled = true
      this.startLoadingBar()
      this.recorder = await this.recordAudio()
      this.recorder.start()
    },
    async playButtonClicked() {
      const audioUrl = URL.createObjectURL(this.audioBlob)
      this.audio = await new Audio(audioUrl)
      await this.audio.play()
    },
    async stopButtonClicked() {
      this.recording = false
      clearInterval(this.counterInterval)
      this.loadingBarValue = 0
      this.recordButtonDisabled = false
      this.stopButtonDisabled = true
      this.playButtonDisabled = false
      this.audioBlob = await this.recorder.stop()
      this.$emit('created-audio', this.audioBlob)
    },
    deleteButtonClicked() {
      this.recordButtonDisabled = false
      this.stopButtonDisabled = true
      this.playButtonDisabled = true
      this.recorder = undefined
      this.audio = undefined
      this.audioBlob = undefined
      this.$emit('removed-audio')
    },
    // eslint-disable-next-line no-async-promise-executor
    recordAudio: () => new Promise(async resolve => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm;codecs=opus' })
      let audioChunks = []

      mediaRecorder.addEventListener('dataavailable', event => {
        audioChunks.push(event.data)
      })

      const start = () => {
        audioChunks = []
        mediaRecorder.start()
      }
      // eslint-disable-next-line no-shadow
      const stop = () => new Promise(resolve => {
        mediaRecorder.addEventListener('stop', () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/webm;codecs=opus' })
          resolve(audioBlob)
        })
        mediaRecorder.stop()
        stream.getTracks().forEach(track => track.stop())
      })
      resolve({ start, stop })
    }),
    startLoadingBar() {
      this.loadingBarValue = 0
      this.counterInterval = setInterval(() => {
        this.loadingBarValue += 1
        if (this.loadingBarValue >= 300) {
          clearInterval(this.counterInterval)
          this.loadingBarValue = 0
          if (this.recording) {
            this.stopButtonClicked()
          }
        }
      }, 50)
    },
  },
}
</script>

<style>

</style>
