<template>
  <div>
    <b-row>
      <b-col>
        <emma-network-chart :active-connection="selectedEmma.activeNetworkInterface" />
      </b-col>
    </b-row>
    <b-row v-if="selectedEmma.availableNetworks">
      <b-col>
        <available-networks :available-networks="selectedEmma.availableNetworks" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <emma-wifi-configurator />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <battery-status />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <emma-thermic-curve-graph
          :update-enabled="autoUpdate"
          :selected-emma="selectedEmma"
          :selected-emma-settings="selectedEmmaSettings"
        />
      </b-col>
      <b-col md="6">
        <emma-cpu-usage-curve-graph
          :update-enabled="autoUpdate"
          :selected-emma="selectedEmma"
          :selected-emma-settings="selectedEmmaSettings"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue"
import EmmaThermicCurveGraph from "@/components/emma/graphs/EmmaThermicCurveGraph.vue"
import EmmaCpuUsageCurveGraph from "@/components/emma/graphs/EmmaCpuUsageCurveGraph.vue"
import EmmaModemSignalCurveGraph from "@/components/emma/graphs/EmmaModemSignalCurveGraph.vue"
// import EmmaMovementBarGraph from '@/components/emma/graphs/EmmaMovementBarGraph.vue'
import EmmaWifiConfigurator from "@/components/emma/tech/EmmaWifiConfigurator.vue"
import EmmaNetworkChart from "@/components/emma/graphs/EmmaNetworkChart.vue"
import AvailableNetworks from "@/components/emma/tech/AvailableNetworks.vue"
import BatteryStatus from "@/components/emma/tech/BatteryStatus.vue"

export default {
  name: "OrganisationTechDashboard",
  components: {
    BatteryStatus,
    BRow,
    BCol,
    // BButton,
    EmmaThermicCurveGraph,
    EmmaCpuUsageCurveGraph,
    EmmaModemSignalCurveGraph,
    // EmmaMovementBarGraph,
    EmmaWifiConfigurator,
    EmmaNetworkChart,
    AvailableNetworks,
  },
  data() {
    return {
      autoUpdate: false,
    }
  },
  computed: {
    selectedEmma() {
      return this.$store.state.emmasettings.selectedEmma
    },
    selectedEmmaSettings() {
      return this.$store.state.emmasettings.settings
    },
    datasetOrganizationTime() {
      const dataset = []
      const values = []
      for (let i = 0; i < 15; i += 1) {
        values.push(Math.ceil(Math.random() * 100))
      }
      dataset.push(values)
      return dataset
    },
  },
  async mounted() {
    this.id = Math.floor(Math.random() * 100)
    // await this.$store.dispatch('organizations/getAllOrganizations')
    // await this.$store.dispatch('clusters/getAllClusters')
    // await this.$store.dispatch('localGroups/getAllLocalGroups')
  },
  beforeDestroy() {},
  methods: {},
}
</script>

<style scoped></style>
