<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ $t('timer') }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row class="align-items-end">
        <b-col>
          <b-form-group
            :label="$t('hours')"
            label-for="timer-hours"
          >
            <b-form-input
              id="timer-hours"
              v-model="hours"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :label="$t('minutes')"
            label-for="timer-minutes"
          >
            <b-form-input
              id="timer-minutes"
              v-model="minutes"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :label="$t('seconds')"
            label-for="timer-seconds"
          >
            <b-form-input
              id="timer-seconds"
              v-model="seconds"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label=""
            label-for="start-button"
          >
            <b-button
              id="start-button"
              block
              variant="primary"
              @click="startTimer"
            >
              {{ $t('startTimer') }}
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardBody, BFormInput, BFormGroup, BRow, BCol, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'TimerManager',
  components: {
    BButton,
    BCard,
    BCardBody,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
  },
  props: {
  },
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  },
  mounted() {
  },
  methods: {
    async startTimer() {
      this.hours = Number.parseInt(this.hours, 10)
      this.minutes = Number.parseInt(this.minutes, 10)
      this.seconds = Number.parseInt(this.seconds, 10)

      const durationInSec = this.seconds + this.minutes * 60 + this.hours * 3600

      try {
        const response = await this.$http.post(`/emmas/${this.$store.state.emmasettings.selectedEmma.serial}/timer/durationTimer`, { durationInSec })
        if (response.status === 200) {
          this.makeToast('Timer gestartet', 'ClockIcon', 'success', `Timer für ${this.formatTime(durationInSec)} gestartet`)
          return
        }
      } catch (e) {
        console.error(e)
      }
      this.makeToast('Error', 'ClockIcon', 'danger', 'Timer konnte nicht gestartet werden')
    },
    formatTime(seconds) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const remainingSeconds = seconds % 60

      let result = ''
      if (hours > 0) {
        result += `${hours} Stunde${hours > 1 ? 'n ' : ' '}`
      }
      if (minutes > 0) {
        if (result !== '') {
          result += ', '
        }
        result += `${minutes} Minute${minutes > 1 ? 'n ' : ' '}`
      }
      if (remainingSeconds > 0) {
        if (result !== '') {
          result += 'und '
        }
        result += `${remainingSeconds} Sekunde${remainingSeconds > 1 ? 'n' : ''}`
      }
      return result.trim()
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
