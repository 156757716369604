<template>
  <b-card>
    <b-card-header class-name="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ $t('cpuUtilization') }}
        </h2>
      </div>
    </b-card-header>
    <b-card-body>
      <chartjs-component-line-chart
        v-if="!loading"
        :key="id"
        :height="200"
        :data="chartData"
        :options="chartOptions"
        :plugins="plugins"
      />
      <b-spinner v-else />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCardBody, BCard, BCardHeader, BSpinner,
} from 'bootstrap-vue'
import { $themeColors, $chartColors } from '@themeConfig'
import ChartjsComponentLineChart from '../../charts/ChartjsComponentLineChart.vue'

export default {
  name: 'EmmaCpuUsageCurveGraph',
  components: {
    ChartjsComponentLineChart,
    BCardBody,
    BCard,
    BCardHeader,
    BSpinner,
  },
  props: {
    selectedEmma: {
      type: Object,
      required: true,
    },
    updateEnabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      id: 0,
      cpuData: [],
      autoUpdate: undefined,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  computed: {
    chartOptions() {
      return {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
          borderColor: $themeColors.dark,
          borderWidth: 1,
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: $chartColors.grid_line_color,
                zeroLineColor: $chartColors.grid_line_color,
              },
              ticks: {
                fontColor: $chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: 10,
                min: 0,
                max: 100,
                fontColor: $chartColors.labelColor,
              },
              gridLines: {
                display: true,
                color: $chartColors.grid_line_color,
                zeroLineColor: $chartColors.grid_line_color,
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      }
    },
    chartData() {
      const list = []
      const cpuData = []
      for (let i = 0; i < this.cpuData.length; i += 1) {
        const date = new Date(this.cpuData[i].timestamp)
        const minutes = (date.getMinutes() < 10) ? `0${date.getMinutes()}` : date.getMinutes()

        list.push(`${date.getHours()}:${minutes}`)

        const cpu = JSON.parse(this.cpuData[i].value).value
        cpuData.push(cpu)
      }

      const datasets = [{
        label: this.$t('cpuUtilizationInPercent'),
        data: cpuData,
        borderColor: '#668caa',
        lineTension: 0.3,
        pointStyle: 'circle',
        backgroundColor: '#668caa',
        fill: false,
        pointRadius: 3,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 5,
        pointBorderColor: 'transparent',
        pointHoverBorderColor: $themeColors.white,
        pointHoverBackgroundColor: '#668caa',
        pointShadowOffsetX: 1,
        pointShadowOffsetY: 1,
        pointShadowBlur: 5,
        pointShadowColor: $chartColors.tooltipShadow,
      }]

      return {
        labels: list,
        datasets,
      }
    },

  },
  async mounted() {
    this.loading = true
    try {
      this.cpuData = await this.$http.get(`/emmas/${this.selectedEmma.serial}/statistics/sensor/?name=emma/system/cpu/usage&limit=60`)
      this.cpuData = this.cpuData.data.slice(0, 60)
      this.cpuData.reverse()
      this.autoUpdate = setInterval(this.pullCpuData, 6000)
      this.loading = false
    } catch (e) {
      console.error(e)
    }
  },
  beforeDestroy() {
    clearInterval(this.autoUpdate)
  },
  methods: {
    async pullCpuData() {
      if (this.updateEnabled) {
        try {
          let newTemperatureData = await this.$http.get(`/emmas/${this.selectedEmma.serial}/statistics/sensor/?name=emma/system/cpu/usage&limit=60`)
          newTemperatureData = newTemperatureData.data.slice(0, 60)
          newTemperatureData.reverse()
          if (newTemperatureData[0].id !== this.cpuData[0].id) {
            this.cpuData = newTemperatureData
            this.id += 1
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
